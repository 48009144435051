@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.associatedBundles {
  &__heading {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
    margin-bottom: 25px;
    margin-top: -5px;
  }
  &__item {
    cursor: pointer;
  }
}