@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.caselisting {
  &__item {
    display: block;
    justify-content: space-between;
    background: $white;
    padding: 15px 14px 15px;
    cursor: pointer;
    margin-top: 7px;
    @extend %shadow--1;

    &:hover {
      @extend %shadow--2;
    }
  }

  &__left {
    display: inline-block;
    vertical-align: top;
    width: 130px;
  }

  &__leftcontent {
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;

    p {
      font-size: 12px;
      line-height: 18px;
      color: $gray--darkest;
      font-family: $sans-reg;
      margin: 0;

      strong {
        font-family: $sans-bold;
        color: $gray--darker;
      }
    }
  }

  &__nocasetype {
    margin-top: 11px;
  }

  &__right {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 130px);
    padding-left: 25px;
    margin-top: 11px;

    p {
      font-size: 14px;
      line-height: 18px;
      color: $gray--darkest;
      font-family: $sans-bold;
    }

    .caselisting__notitle {
      color: $gray--dark;
    }
  }

  &__iconwrap {
    background-color: $bcggreensix;
    margin-right: 6px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    display: inline-block;
    vertical-align: top;

    img.ui.image {
      display: inline;
      margin-top: 13px;
      margin-left: 1px;
    }
  }
}