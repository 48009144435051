@import "src/mixins/mixins";

.contributeknwldg {

  padding: 0;

  &__icon {
    width: 15px;
    height: 15px;
  }

  .tooltipwrap {
    margin-left: 0;
  }

  &__text {
    font-size: 12px;
    letter-spacing: 0.8px;
    line-height: 15px;
    padding-left: 12px;
    font-family: $sans-reg;
  }

  &:hover {
    color: $white;
  }

  &__button {
    @include buttonBlack($white, $black, 10px 12px);
    padding: 10px 12px;
    display: block;
    cursor: pointer;

    img.ui.image {
      display: inline;
    }

    &--disable {
      opacity: 0.5;
    }
  }
}