@import "src/global-styles/colors";
@import "src/global-styles/typography";

.customize {
  &__label {
    .ui.form .field>& {
      display: inline-block;
    }
  }

  &__dropdown {
    width: 22%;
    min-width: 256px;
    position: relative;
  }
  &__dropdowntxt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 16px 12px;
    background: $white;
    border-radius: 4px;
    font-size: 14px;
    line-height: 24px;
    color: $gray--darkest;
    cursor: pointer;
    font-family: $sans-reg;
    border: 1px solid $gray--dark;
  }
  &__dropdownlist {
    max-height: 270px;
    overflow: auto;
    display: none;
    background: $white;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(112, 110, 107, 0.5);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  &__dropdownshow {
    display: block;
  }
  &__dropdownstyle {
    cursor: pointer;
    & .option {
      padding: 13px;
      display: inline-block;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      color: $gray--darkest;
      font-family: $sans-reg;
    }
    ul {
      display: none;
    }
    &.selected .option {
      background: $black;
      color: $white;
    }
  }
  &__dropdownsublist {
    & .option {
      padding: 11px 11px 11px 37px;
    }
  }
  &__dropdown--hierarchical {
    position: relative;
    & > .option {
      padding: 11px 11px 11px 25px;
    }
    &:after {
      background: url(../../../assets/images/chevronright.svg) no-repeat;
      content: "";
      position: absolute;
      width: 8px;
      height: 12px;
      top: 18px;
      left: 10px;
    }
  }
  &__dropdownchild--show {
    > ul {
      display: block;
    }
    &:after {
      transform: rotate(90deg);
    }
  }
  &__dropdowntop {
    top: auto;
    bottom: 100%;
  }
  &__dropdownarrow {
    width: 0;
    height: 0;
    top: 9px;
    position: relative;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 6px solid $black;
    border-radius: 1px;
  }
  &.customhighlight > span {
    border-color: $notify-warn-highlight;
  }
  &__dropdown--single .option:hover {
    background: $black;
    color: $white;
  }
}
