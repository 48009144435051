@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.bulleteditor {
    &__editbutton {
        font-size: 10px;
        margin-left: 10px;
    }



    &__edit_icon {
        margin-left: 3px;
        top: -3px;
        width: 35px;
        height: 35px;
        cursor: pointer;
    }

    &__heading {
        font-size: 16px;
        font-family: $sans-trebuchet;
        margin-bottom: 9px;
        color: $bcggreenthree;
        font-weight: normal;

        &-black {
            color: $blackfour;
        }

        .tooltipwrap__popup.content {
            line-height: 16px;
            margin-top: -4px;
            padding: 8px 10px;
        }
    }

    .counter {
        display: none;
    }

    .rdw-editor-wrapper {
        position: relative;
        min-height: inherit;
    }

    .rich__toolbar {
        position: absolute;
        top: -28px;
        right: 0px;
        padding: 0;
        border: 0;

        .rdw-inline-wrapper {
            margin-right: 0;
        }

        .rdw-option-wrapper {
            border-color: $gray--dark;
        }
    }

    .rich__editor {
        border-bottom: 1px solid $gray--dark;
        padding: 0;
        box-sizing: border-box;
        width: 100%;
        overflow-y: visible;
        height: auto;
        min-height: inherit;
        border-radius: 3px;

        h1,
        h2,
        h3,
        h4,
        h5 {
            font-weight: normal;
            font-size: inherit;
        }
    }

    &__error {
        .rich__editor {
            border-color: $notify-warn-highlight;
        }
    }

    &__errormsg {
        margin-top: 5px;
        font-size: 12px;
    }

    &__warningIcon.ui.image {
        display: inline;
        margin-right: 10px;
    }

    .contentsec {
        ul {
            margin-left: 20px;
            font-size: 14px;
            color: $blackfour;
            font-family: $sans-trebuchet;
        }

        li {
            list-style: disc;
        }

        p {
            font-size: 14px;
            color: $blackfour;
            font-family: $sans-trebuchet;
            margin-bottom: 0;
            min-height: 21px;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            font-weight: normal;
            font-size: inherit;
        }
    }

    &--green {
        .bulleteditor__heading {
            color: $white;
        }

        .contentsec {

            ul,
            p {
                color: $white;
            }

            li::marker {
                color: $white;
            }
        }
    }
}