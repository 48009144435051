@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.delete-modal{
    width: 28%;
    padding: 22px 15px 30px;
    &__title{
        display: flex;
        align-items: flex-start;  
        margin-bottom: 30px;  
    }
    &__heading{
        letter-spacing: 0;
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;
        color: $gray--darkest;
        font-family: $sans-bold;
        margin:0 0 0 11px;
    }
    &__img{
        padding: 10px 12px;
        border-radius: 5px;
        background-color: rgba(213,0,0,0.1);
    }
    &__btn-group{
        text-align: center;
    }
    &__btn.ui.button:first-child{
        margin-right: 30px;
    }
}