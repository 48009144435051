@import "src/global-styles/colors";

.toggleSwitch {
	&.ui.fitted.toggle.checkbox {
		width: 48px;
		height: 22px;

		& input {
			width: 46px;
			height: 22px;

			&:checked~label {
				&:before {
					background-color: $bcg-green--light-blue-green !important;
					left: -1px;
				}

				&:after {

					left: 25px;
					top: -0.5px;
				}
			}

			&~label:after {
				left: 0px;
				top: -0.5px;
			}
		}

		& label {
			margin: 0;
			padding: 0;
			min-height: 22px;

			&:before {
				width: 46px;
				height: 22px;
				background-color: $white;
				border: 1px solid $bcg-green--dark !important;
			}

			&:after {
				width: 23px;
				height: 23px;
				top: 0px;
				left: -1px;
			}
		}
	}

	&.ui.slider.checkbox {
		label {
			&:after {
				border: 1px solid green;
				height: 16px;
				width: 16px;
				top: 1px;
			}

			&:hover {
				&:after {
					border-width: 2px;
				}
			}

			&:before {
				width: 35px;
				height: 6px;
			}
		}

		input:checked {
			&~label:before {
				background-color: green !important;
			}

			&~label:after {
				left: 20px;
			}
		}
	}
}