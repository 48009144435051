@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.FCGfaceCardHeader {
    width: 100%;
    padding: 17px 0px;
    background: url("../../assets/images/FCG_header_pattern1.svg") repeat 0 0;
    position: sticky;
    top: 0;
    z-index: 0;
    overflow: hidden;
    // transition: 0.9s ease;

    &-scrolled {
        height: 85px;
        z-index: 3;
    }

    &__headerBox {
        margin: 0 auto;
        max-width: 1400px;
        padding-bottom: 40px;
    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        padding: 10px 24px;
        z-index: 1;
    }

    &__left,
    &__right {
        display: flex;
        align-items: center;
    }

    &__textGroup {
        color: $white;
        padding: 10px 43px;
        margin-top: 20px;
        font-family: $sans-light;
        opacity: 1;
        padding-left: 0px;

        p {
            font-size: 24px;
        }
    }

    &__logoIcon {
        margin-right: 14px;
    }

    &__logoText {
        color: $white;
        font-size: 24px;
        font-family: $sans-bold;

    }

    &__left {
        margin-left: 24px;
        flex-direction: column;
        align-items: normal;
    }

    &__logo {
        display: flex;
        align-items: center;

        &-pointer {
            cursor: pointer;
        }
    }

    &__right {
        display: flex;
        position: relative;
        width: 234px;
    }
}