@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

%tx-message-styles {
  font-size: 14px;
  padding-bottom: 17px;
  line-height: 0;
}

.sectorsWrapper {
  &__tab-component {
    min-height: auto;

    .ui.attached.segment {
      background-color: $gray--lightest;
    }
  }

  &__tx-message {
    @extend %tx-message-styles;

    &--sector {
      margin-bottom: 4px;
    }
  }
}