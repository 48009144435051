@import "src/global-styles/colors";
@import "src/mixins/_mixins";


.layout{
  &__caseConsumption{
    .loader__wrap{
      margin: 100px 0;
    }
  }
}
.caseConsumption {
  .sectionWrapper {
    height: 42px;
  }
  &__topbar {
    background-color: $gray--darkest;
  }
  .cpconsumption {
    &__gap {
      padding: 0 0 32px;
    }
  }
  &__caseSummaryWrapper {
    background-color: $white;
    padding-top: 32px;
    @include beyondLayout(30px);
  }
  &__caseSummary {
    display: flex;
    padding-bottom: 32px;
    &__section {
      display: inline-block;
      .caseType{
        padding: 5px 28px 5px 10px;
      }
      div {
        display: inline;
      }
      &.right {
        margin-left: auto;
      }
    }
  }
  &__caseAssetsWrapper {
    padding-bottom: 32px;
    .tabs {
      padding-right: 12px;
    }
  }
  &__taxonomyWrapper {
    .consumptionTabContainer {
      div.ui.secondary.pointing.menu,
      div.ui.bottom.attached {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &__caseAssetsListing {
    &__heading {
      @include textStyle($sans-reg, 14px, $gray--darkest, normal, 17px);
      margin-bottom: 20px;
    }
    .kpList {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      .kpitem {
        &__ctas {
          margin-top: 4px;
          justify-content: flex-start;
          &__inner {
            margin-right: 2px;
          }
        }
      }
    }
  }
  .tabs {
    &__container {
      min-height: 0px;
      .ui.segment {
        background: transparent;
        padding: 0px 0px;
      }
      .ui.secondary.pointing.menu .item {
        padding: 0 0 13px 0px;
        margin-right: 15px;
      }
    }
  }

  .consDescripv1 {
    background-color: $white;
    @include beyondLayout(30px);
    &__headingSection {
      margin-top: 0;
      margin-bottom: 0;
      padding-bottom: 32px;
    }
  }
  &__maincontent {
    padding-top: 32px;
    padding-bottom: 32px;
    background-color: $gray--lightest;
    &__column {
      display: inline-block;
      vertical-align: top;
    }
    &-desc {
      width: calc(60% - 32px);
      margin-right: 32px;
      .csdescription {
        padding-top: 0;
        &__col {
          max-width: 100%;
        }
      }
      .tabs {
        &__container {
          div.ui.secondary.pointing.menu {
            margin-left: 0;
            margin-right: 0;
            .item {
              margin-right: 15px;
            }
          }
        }
      }
    }
    &-contacts {
      width: 40%;
      border-radius: 6px;
      padding: 16px 16px 0px 16px;
      background-color: $white;
      box-shadow: 0 0 4px 0 rgba(51, 51, 51, 0.3);
    }
  }
}
