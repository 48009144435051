// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.kpstep1 {
  .search-related-container {
    color: #333;
    font-family: HendersonBCGSans-Regular, Arial, sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;
    letter-spacing: normal;
    line-height: 1.71;
    align-items: center;
  }

  &_tx_subheading {
    font-size: 12px;
  }

  &_tx_info {
    font-size: 12px;
    color: $dark-orange;

    &icon.ui.image {
      display: inline-block;
      margin-left: 10px;
      margin-right: 7px;
      transform: rotate(180deg);
      height: 13px;
    }
  }

  &__uploadnote {
    font-size: 12px;
  }

  &__casesuggestion {
    margin-top: 20px;
  }

  &__error {
    min-height: 30px;
    display: inline-block;

    img.ui.image {
      display: inline-block;
      margin-right: 10px;
    }
  }

  &__errormsg {
    position: absolute;
    @include errorMsg;
  }

  .ui.form & .fields {
    & .add-supportinglinks__wrapper {
      margin-bottom: 0px;

      &.inline.fields {
        >.field {
          width: calc(50% - 20px);
        }
      }
    }

    .add-url {
      display: inline-block;
    }

    .titleField {
      input {
        padding-right: 79px !important;
      }
    }

    .field {
      input {
        font-family: $sans-reg;
      }

      &.add-supportinglinks__button {
        width: 167px;
        padding-right: 0px;
      }
    }

    span.counter {
      position: absolute;
      right: 20px;
      top: 18px;
      background-color: $white;
      padding-left: 10px;
    }

    //field - rich text editor
    .richEditor {
      position: relative;
    }

    .additionalUrls {
      .add-supportinglinks {
        padding: 20px 10px;

        &__multiTiles {
          margin: 0 20px;
        }
      }
    }

    .add-supportinglinks {
      @include borderedField;
      padding: 0;

      label.add-supportinglinks__label::before {
        content: none;
      }
    }
  }

  .ui.form & .grouped.fields {
    margin-left: -0.5em;
    margin-right: -0.5em;

    >.field {
      padding-left: 0.5em;
      padding-right: 0.5em;

      .grouped-items label {
        height: 24px;
        @include textStyle($sans-reg, 14px, $gray--darkest, bold);

        &:before {
          content: none;
        }
      }
    }
  }

  .grouped-items {
    border: 1px solid $checkboxgray;
    border-radius: 7px;
    padding: 10px 20px;

    .custodianWrapper {
      margin-top: 30px;
    }

    div.customhighlight {
      border: 1px solid $notify-warn-highlight;
      border-radius: 4px;
      padding: 5px 10px;
    }

    &:last-child {
      margin-bottom: 10px;
    }

    .field {
      margin: 0;
    }

    .nudgepopup__suggestions {
      left: calc(100% + 40px);
      top: -11px;
      width: 62%;
    }

    .nudgepopup__righttop {
      top: -38px;
      right: -17px;
    }
  }

  .searchBoxWrapper {
    margin-top: 6px;

    &__searchForm {
      &.ui.form {
        >.fields {
          padding-left: 0;

          >.field {
            >label {
              &::before {
                content: none;
              }
            }
          }
        }
      }
    }

    &__results {
      top: 85px;
      z-index: 2;
    }
  }

  .ui.form .knowledgepage__wrap & .mdpApproved__wrapper {
    margin-bottom: 15px;

    &__disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    .mdpApproved {
      display: flex;
      margin-top: 18px;

      &__wrapper {
        margin-bottom: 15px;
      }

      .field {
        margin-right: 42px;
      }

      &+.mdpNo__info {
        display: inline-flex;
        gap: 5px;
        border: 1px solid $gray--lightfive;
        border-radius: 4px;
        background: $white;
        font-size: 12px;
        padding-left: 4px;
        line-height: 24px;
        font-weight: normal;
        margin-top: 9px;
        color: $black;
        padding-right: 10px;

        &__hidden {
          visibility: hidden;
        }

        img {
          width: 14px;
        }
      }
    }
  }

  .radioMdp_error {
    .ui {
      &.radio {
        &.checkbox {
          label::before {
            border: 1px solid $notify-warn-highlight;
          }
        }
      }
    }
  }

}