@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.searchBoxWrapper {
	position: relative;

	&__label {
		@include textStyle($sans-reg, 14px, $black, bold);

	}

	.ui.form .field &__labelwithtooltip {
		display: inline-block;
	}

	&__link,
	&__link:hover {
		color: $gray--darkest;
	}

	&__link:hover,
	&__link span:hover {
		text-decoration: underline;
	}

	&__selecteditem {
		font-size: 14px;
		padding: 4px 11px 5px;
		display: inline-block;
		vertical-align: top;
		border-radius: 5px;
		background-color: $gray--light;
		margin: 7px 0px 0px 0;

		span {
			padding: 0 10px 0 0;
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			color: $gray--darkest;
			font-family: $sans-reg;
			line-height: 25px;
		}

		.ui.image {
			display: inline-block;
			vertical-align: top;
			width: 20px;
			border-radius: 17px;
			object-fit: cover;
			height: 20px;
			margin-top: 2px;
			cursor: pointer;
		}
	}

	&__input {
		.ui.input {
			input {
				padding: 15px;
			}
		}

		&__searchIcon.ui.image {
			position: absolute;
			top: 0px;
			bottom: 0px;
			margin: auto;
			right: 15px;
			cursor: pointer;
			height: 22px;
		}
	}

	&__results {
		width: 100%;
		z-index: 1;
		padding: 8px;
		border: 1px solid $gray--dark;
		border-radius: 6px;
		list-style: none;
		position: absolute;
		left: 0;
		top: 91px;
		background-color: $white;
		overflow-y: auto;
		max-height: 280px;

		&__label {
			@include textStyle($sans-bold, 12px, $gray--darkest, bold, 20px);
			margin-bottom: 16px;
		}

		&__noResults {
			@include textStyle($sans-reg, 16px, $gray--darkest, normal, 40px);
			margin-bottom: 8px;
		}

		&__item {
			border-bottom: 1px solid $gray--light;
			cursor: pointer;

			&:last-child {
				border-bottom: none;
			}
		}

		&__item__disabled {
			cursor: default;
			background: $gray--lightest;

			.caseSearchItem__caseNumber,
			.caseSearchItem__caseTitle {
				color: $gray--dark;
			}
		}

		&__tooltip {
			margin-left: 0;
			width: 100%;

			.tooltipwrap__popup {
				padding: 10px;
				max-width: 270px;
			}

			&__txt {
				display: flex;

				>img {
					margin: 3px 10px 0 0;
					width: 14px;
					height: 14px;
				}

				>span {
					font-family: $sans-bold;
				}
			}
		}
	}

	&__error {
		@include errorMsg;
	}

	.ui.form .fields {
		margin-bottom: 15px !important;

		.searchBoxWrapper {
			&__multipleCase {
				border: 1px solid $gray--dark;
				background: $white;
				border-radius: 5px;
				padding: 4px;

				&__caseno {
					z-index: 1;
					display: flex;
					column-gap: 6px;
					flex-wrap: wrap;
					margin-left: 10px;
					margin-bottom: 10px;
				}
			}

			&__input {
				flex-grow: 1;

				.ui.input {
					input {
						border: none;
					}
				}
			}
		}
	}

	&.searchBox__disabled {
		.searchBoxWrapper__label {
			opacity: .5;
		}

		.searchBoxWrapper__input {
			input {
				opacity: .5;
				background: $gray--light;
				pointer-events: none;
			}

			&__searchIcon {
				opacity: .5;
				pointer-events: none;
			}
		}
	}
}

.searchBox__error {
	border-color: $notify-warn-highlight !important;
}