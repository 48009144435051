@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.ellipsiswrapper {
  position: relative;

  &__ellipsis.ui.button {
    background-color: #fff;
    margin-left: 10px;
    border: 1px $gray--whitish;
    padding: 5px;
    width: 35px;
    height: 30px;

    &:hover,
    &:focus,
    &:active {
      background: $gray--light;
    }
  }

  &__content.ui.popup {
    z-index: 11;
    display: block;
    background-color: $white;
    border-radius: 4px;
    box-sizing: border-box;
    width: 180px;
    top: 27px;
    right: 3px;
    margin-left: 48px;
    position: absolute;
    padding: 0;

    &:before {
      content: none;
    }
  }

  &__dots {
    height: fit-content;
    margin-top: 10%;
  }

  .kea {
    &__btn {
      color: $gray--blackish;
      font-family: $sans-reg;
      font-size: 14px;
      line-height: 16px;
      width: 100%;
    }

    &__btn--disabled {
      background-color: $gray--light;
      opacity: 1;
      color: $gray--dark;
    }

    &__list-item {
      padding: 10px;
    }
  }
}