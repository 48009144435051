@import "src/global-styles/typography";
@import "src/global-styles/colors";

.ui.form .field {
	.labelWithText {
		display: flex;
		justify-content: space-between;
		align-items: center;

		&__leftalign {
			justify-content: left;

			.labelWithText__txt {
				margin-left: 10px;
			}
		}

		&__txt {
			color: $gray--darker;
			font-family: $sans-bold;
			font-size: 12px;
			letter-spacing: 0;
			line-height: 11px;

			&--icon {
				margin-right: 10px;
			}


		}

		&__value {
			box-sizing: border-box;
			padding: 4px 12px;
			border: 1px solid $gray--darkest;
			border-radius: 12px;
			background-color: $white;
			color: $gray--darkest;
			margin-left: 7px;
		}
	}
}