@import "src/global-styles/colors";
@import "src/global-styles/typography";

.mycases {
    &__filters {
        padding: 17px 0 24px;
        li {
            display: inline-block;
            vertical-align: top;
            margin-right: 10px;
        }
        .ui.avatar.image {
            height: 32px;
            width: 32px;
        }
    }
    &__kmsowner {
        padding: 4px 6px !important;
    }
    &__kmsownerlabel {
        font-size: 12px;
        color: $gray--darkest;
        line-height: 24px;
        font-family: $sans-reg;
        margin-left: 5px;
    }
    &__filterlabel {
        font-size: 14px;
        line-height: 24px;
        font-family: $sans-bold;
        padding-top: 7px;
    }
    &__large {
        width: 200px;
    }
    &__mid {
        width: 170px;
    }
    &__small {
        width: 150px;
    }
    &__bottombar {
        position: relative;
    }
    &__showing {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        float: left;
        align-items: center;
        vertical-align: middle;
        font-size: 12px;
        color: $gray--darker;
        line-height: 24px;
        font-family: $sans-reg;
    }
}
