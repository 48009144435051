@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.associationsWrapper {
  background-color: $gray--lightest;
  &__tab-component {
    min-height: unset;
    .ui.attached.segment {
      background-color: $gray--lightest;
      margin-top: -10px;
    }
  }
}