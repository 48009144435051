@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.drawfromright {
  &.ReactModal {
    &__Overlay {
      opacity: 0;
      transform: translateX(100px);
      transition: all 200ms ease-in-out;
      &--after-open {
        opacity: 1;
        transform: translateX(0px);
      }
      &--before-close {
        opacity: 0;
        transform: translateX(100px);
      }
    }
  }
}

.adminhistorymodal {
  height: 100%;
  width: 90%;
  left: 55%;
  padding: 0;
  font-family: $sans-reg;
  border: none;
  border-radius: 0;
  box-shadow: 0 6px 30px 0 rgba(0,0,0,0.32);
  &__header {
    background-color: $gray--darkest;
    padding: 20px;
    display: flex;
  }
  &__headertitle {
    margin-left: 15px;
    color: $white;
    font-size: 16px;
    font-family: $sans-bold;
    letter-spacing: 1.07px;
    line-height: 35px;
  }
  &__headerclose {
    background: none;
    color: $white;
    font-size: 24px;
    line-height: 20px;
    margin-left: auto;
    height: 20px;
    width: 20px;
    outline: none;
    padding: 0;
    border: none;
  }
  &__headericon.ui.image {
    padding: 8px;
    border-radius: 4px;
    width: 35px;
    background: $black;
    height: 35px;
  }
  &__columns {
    display: flex;
    padding: 16px 20px;
    justify-content: space-between;
  }
  &__column {
    color: $gray--darkest;
    font-size: 14px;
    font-family: $sans-bold;
    letter-spacing: 0;
    line-height: 17px;
    flex: 1 1 140px;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    &--fixed {
      flex: 0 0 150px;
      max-width: 150px;
    }
    &--person {
      flex: 0 0 350px;
      display: flex;
    }
    &--title {
      display: flex;
      flex: 1 1 400px;
    }
  }
  &__content {
    padding: 14px 20px;
    background-color: $gray--light;
  }
}