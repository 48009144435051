@import "src/global-styles/colors";
@import "src/global-styles/typography";

.consumptionShare {
    margin-top: -5px;

    .ui.popup {
        &::before {
            content: none;
        }

        z-index: 11;
        display: block;
        background-color: $white;
        border-radius: 4px;
        box-sizing: border-box;
        width: 135px;
        margin-left: 0;
        padding: 0;
        text-align: left;

        li {
            color: $gray--blackish;
            font-family: $sans-reg;
            font-size: 14px;
            line-height: 16px;
            width: 100%;
            padding: 10px;
            cursor: pointer;
        }
    }

    &__content {
        &--copy_icon.ui.image {
            display: inline;
            height: 10px;
            width: 20px;
            margin-right: 5px;
        }

        &--copy_bold {
            font-weight: bold;
        }
    }

    .infopopup__trigger {

        &:hover,
        &:active,
        &:focus {
            background: none;
        }

        .kb--share {
            opacity: 0.5;
            width: 32px;
            margin-top: -4px;
            margin-left: -3px;
        }
    }

}