@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

.layout {
  .main {
    background-color: $gray--lightest;
  }

  &__my-navigator {
    padding-top: 160px;
    margin: -38px 0 0;
    position: relative;
    background: transparent;
    padding-left: 30px;
    padding-right: 30px;

    .container {
      padding: 11px 0 0;
    }

    // .ui.attached.segment {
    //   display: none;
    // }
  }
}

.banner {
  &__topbarWorkspace {
    top: 16px;
    border-bottom: 1px solid $gray--dark;
    border-radius: 13px 13px 0 0;
  }

  .breadCrumb-Navigator {
    padding-top: 13px;

    .title {
      font-family: $sans-bold;
      font-size: 20px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
}

.my-navigator {
  position: relative;

  &__content {
    padding: 20px 0 0;
    @include getLayoutInnerPadding();
  }

  &__contributeBtn {
    @include getLayoutInnerPadding();
    position: fixed;
    top: 139px;
    z-index: 1006;
    width: calc(100% - 60px);

    .contributeknwldg {
      position: absolute;
      right: 134px;
      top: 0px;
    }
  }



  &__status-pill,
  &__status-pill--active {
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    border-radius: 13.5px;
    background-color: $gray--light;
    padding: 6px 16px;
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
  }

  &__status-pill--active {
    color: $white;
    background-color: $black;
    cursor: default;
  }

  &__search {
    padding-bottom: 25px;
  }
}

.tabs {
  &__my-navigator {
    &--disabledtab {
      opacity: 0.5;
      cursor: auto;
    }

    .ui.attached.bottom.segment {
      padding-left: 0;
      padding-right: 0;
    }

    &--loadingcontent {
      .ui.attached.bottom.segment {
        position: relative;
        height: 100vh;
        overflow: hidden;

        &>div {
          height: 100vh;
          overflow: hidden;
        }
      }

      .fullpageloader__wrap--insection {
        top: 15px;
      }
    }

    .ui.secondary.pointing.menu {
      gap: 20px;
      margin-left: 0;
      height: auto;
      min-height: 75px;
      position: fixed;
      background: $white;
      width: calc(100% - 60px);
      margin-top: -60px;
      z-index: 1005;
      border-radius: 13px 13px 0 0;
      border-bottom: 1px solid $gray--darkest;
      padding: 0;
      @include getLayoutInnerPadding();

      .item {
        padding-bottom: 27px;
        width: 202px;
        display: block;
        white-space: nowrap;
        max-width: 202px;

        &-style {
          text-align: center;
        }
      }

      .leftElement {
        padding-top: 0px;
        margin-right: 5px;

        &__count {
          background: none;
          color: $gray--darkest;
          font-size: 36px;
          padding: 0;
          line-height: 40px;
          margin-left: 14px;
          border-right: 1px solid $gray--dark;
          padding-right: 20px;
          border-radius: 0;
          font-family: $sans-reg;
          font-weight: 400;
        }
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
.customTooltipClass {
  white-space: normal;
}

.disabled-tab-tooltip {
  opacity: 0.5;
  pointer-events: auto;
  cursor: not-allowed;
  white-space: nowrap;
}

.opacityTab{
  pointer-events: none; 
  cursor: not-allowed;
  }