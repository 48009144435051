@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.faceCardDefault {
    text-align: center;
    padding: 10px;

    &__image.ui.image {
        display: inline-block;
        width: 400px;
    }

    &__text {
        color: $gray--lighttfour;
        text-align: center;
        font-family: $sans-bold;
        font-size: 22px;
        padding: 10px;

        &--error {
            margin: 0;
            padding: 0;
        }

    }


    &__arrow.ui.image {
        color: $gray-500;
        width: 20px;
        height: 15px;
        margin-bottom: 6px;
        display: inline-block;
    }

    &__para {
        margin-top: 20px;
        text-align: center;
    }
}