@import 'src/global-styles/colors';


.layout_Kbconsumption {
  padding: 0px;
  margin: 0px;
  background-color: $white;
  width: 100%;
  margin-top: -10px;

  &--footer {
    margin-top: 30px;
  }

  &__topbar-color {
    background-color: $bcg-blue--darktwo;
  }

  &__tabs {
    background-color: $gray--lightest;

    .ui.secondary.pointing.menu .item {
      padding: 13px 5px;
      margin-right: 15px;
    }

    .taxonomylisting {
      margin-top: -12px;
    }
  }
}