@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

.layout {
  // Sticky footer styles
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  height: 100%;
  min-width: 1240px;
  //End sticky footer styles

  &>.ui.menu.menu {
    z-index: 1006 !important;
  }

  &__poc {
    margin: 100px auto;
    width: 500px;
  }

  .hidevisibility {
    visibility: hidden;
  }

  &--unscrollable {
    height: 100vh;
    overflow: hidden;
  }

  .nopointer {
    pointer-events: none;
  }

  .disabledIcon {
    opacity: 0.5;
  }

  .consumptionTabContainer {
    >div.ui {
      &.secondary.pointing.menu {
        @include getLayoutInnerPadding();
      }

      &.bottom.attached {
        @include getLayoutInnerPadding();
      }
    }
  }

  .consumptionDivider {
    max-width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    box-sizing: border-box;
    height: 1px;
    display: block;
    border-top: 1px solid $gray--dark;
  }

  .margin_bottom--30 {
    margin-bottom: 30px;
  }

  .displaynone {
    display: none !important;
  }

  .tempplaceholder {
    background: $gray--dark;
    padding: 10px;
  }

  &__cpconsumption {
    margin: 0;
    padding: 0;
  }


  .main {
    /* IE11+ don't support <main> */
    position: relative;
    height: 100%;
    width: 100%;
    margin-top: 57px; //equal to height of header
    background-color: $gray--light;

    &__zoom-applied {
      margin-top: 66px;

      .topbar {
        top: 66px;
      }
    }
  }

  .faceCardMainSec {
    margin-top: 0;
  }

  .layout__fix,
  .footer .container {
    padding-left: 30px;
    max-width: 1620px;
    padding-right: 30px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    &--inner {
      @include getLayoutInnerPadding();
    }
  }

  .footer {
    .container {
      .row {
        @include getLayoutInnerPadding();
      }
    }
  }



  &__page {
    background-color: $gray--lightest;
    margin: 0;
    border-radius: 13px;
    max-width: 100%;
  }

  &__component {
    padding: 0;
    margin: 0;
  }

  .rail {
    background-color: $white;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
  }

  &__knowledgepages,
  &__tpkb {
    padding-left: 30px;
    padding-right: 30px;
  }

  &__tpkb {
    padding-top: 158px;
  }

  &__knowledgepages {
    padding-top: 150px;
  }

  &__casepage {
    padding-top: 160px;
    margin: -32px 0 0;
    position: relative;
    background: transparent;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.optional-text {
  color: $gray--dark;
  padding-left: 5px;
  font-family: $sans-bold;
}

.two-lines-ellipsis {
  @include multilineellipsis(2);
}

.three-lines-ellipsis {
  @include multilineellipsis(3);
}

// Sticky footer styles
#root>div {
  // ` > div` part needed because Okta doesn't use <Fragment> in <Security>
  display: flex;
}