@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.casesList {
	display: flex;
	margin-bottom: 10px;

	&__item {
		@extend %shadow--1;
		border-radius: 10px;
		padding: 20px 15px;
		width: 95%;
		display: flex;

		&__data {
			@include textStyle($sans-reg, 14px, $gray--darkest, normal);
			max-width: 11%;
			flex: 0 0 140px;
			align-items: center;
			padding-right: 20px;

			&--wider {
				flex: 0 0 500px;
				max-width: 38%;
			}

			&--lesswider {
				max-width: 15%;
				flex: 0 0 180px;
			}

			&--midwider {
				max-width: 22%;
				flex: 0 0 180px;

				.hide-migrated {
					display: none !important;
				}
			}

			label {
				display: block;
				@include textStyle($sans-reg, 14px, $black, bold);
			}

			&-link {
				color: $gray--darkest;

				&:hover {
					color: $gray--darkest;
					text-decoration: underline;
				}
			}
		}
	}

	&__delete {
		margin: 0 auto;
		display: flex;
		vertical-align: middle;

		.image {
			cursor: pointer;
		}
	}
}