@import "src/global-styles/colors";
@import "src/global-styles/typography";

.sectionWrapper {
  // this class provide a height to the div so that our content are well below the stick header
  height: 60px;
}

.topbar {
  position: fixed;
  top: 57px;
  width: 100%;
  padding: 7px 0;
  left: 0;
  z-index: 20;
  background-color: $gray--darker;

  &__wrapper {
    display: flex;

    &-info {
      line-height: 24px;
      font-size: 13px;
      color: $white;
      background-color: $gray--darker;

      &.ui.image {
        display: inline-block;
        vertical-align: middle;
        width: 13px;
      }
    }

    &-description,
    &-heading {
      white-space: pre;
      color: $white;
      font-size: 14px;
      line-height: 24px;
      font-family: $sans-reg;
      flex-grow: 1;
    }

    &-heading {
      margin-left: 10px;
      font-family: $sans-bold;
    }

    &-close {
      cursor: pointer;
      color: $white;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  .ui.bannerBtn {
    background-color: $white;
    padding: 13px 18px;
    height: auto;
    margin-left: 10px;
    border: none;

    &:hover {
      background-color: $white;
      color: $gray--darkest;
    }
  }
}