@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.kpitem {
  background-color: $white;
  font-family: $sans-reg;
  padding: 16px;
  letter-spacing: normal;
  border-bottom: 1px solid $gray--dark;
  margin-bottom: 2px;
  max-width: 100%;
  min-height: 90px;

  &:hover {
    box-shadow: 1px 0px 5px $gray--dark;
    cursor: pointer;
  }

  &__pastar {
    color: $bcg-green;
    &.icon {
      font-size: 12px;
    }
  }

  &__content {
    display: flex;
    min-height: 58px;
    width: 100%;
    justify-content: space-between;
    max-width: 100%;
  }
  &__date-type {
    display: flex;
    flex-direction: row;
    flex: 0 0 125px;
    max-width: 12.5%;
    &__date {
      height: 20px;
      color: $gray--darkest;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 5px;
    }
    &__type {
      color: $gray--darker;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.8px;
      text-transform: uppercase;
      @include multilineellipsis(2, 16px);
    }
  }
  &__material {
    flex: 0 0 52%;
    max-width: 52%;
    &__content {
      display: flex;
    }
    &__icon {
      height: 22px;
      width: 16px;
      margin-right: 8px;
      align-self: flex-end;
      &--disabled {
        &:hover {
          cursor: auto;
        }
      }
    }
    &__name {
      @include textStyle($sans-reg, 14px, $gray--darkest, normal, 21px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: $anchor-color;
      }
      &--disabled {
        &:hover {
          color: $gray--darkest;
          cursor: auto;
        }
      }
    }
    &__subtitle {
      @include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &__ctasdisabled {
    opacity: 0.4;
  }
  &__ctaswrap{
    text-align: right;
	width: 138px;  
  }
  &__thumbs{
    margin-top: 16px;
  }
  &__ctas {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 0 0 138px;
    &__inner {
      border: 0;
      background: none;
      &:focus {
        outline: 0;
      }
      &.download-icon {
        .ui.image {
          height: 16px;
          width: 16px;
          &.restricted-download__iconMarkup {
            height: 20.57px;
            width: 16px;
            margin-top: -3px;
          }
        }
      }
    }
    a {
      display: inline-block;
    }
  }
  &__authours {
    display: flex;
    flex-basis: 140px;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 20px;
    &__star {
      color: $bcg-green;
      &.icon {
        font-size: 12px;
      }
    }
    &__recommended {
      flex: 0 0 113px;
      color: $gray--darkest;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}
