@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.additionalcontent {
  background-color: $gray--lightest;
  &__tab-component {
    min-height:auto;
    .ui.attached.segment {
      background-color: $gray--lightest;
    }
  }
}
