// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";
.cpstep4 {
  &__radio {
    display: flex;
  }
  &__assetsListing {
    .kpList {
      margin-bottom: 32px;
      .kpitem {
        &__ctas {
          margin-top: 4px;
        }
      }
    }
  }
  &__assets {
    .knowledge-objects__searchWrap > div {
      flex: 1 1 580px;
      max-width: 65%;
    }
    &__or {
      flex: 0 0 20px;
      max-width: 2%;
      display: flex;
      flex-direction: column;
      margin-top: 32px;
      overflow: hidden;
      max-height: 54px;
      opacity: 0.5;
      @include textStyle($sans-reg, 12px, $gray--brownish, normal, 27px);
      &::after, &::before {
        content: '';
        height: 15px;
        border-top: 1px dashed $checkboxgray;
        transform: rotate(90deg);
        width: 15px;
        margin-left: -5px;
      }
    }
    &__add {
      flex: 0 0 252px;
      max-width: 252px;
      &.ui.black.button {
        background-color: $gray--darkest;
        color: $white;
        margin-top: 40px;
      }
    }
  }
  &__kpModalHeading {
    @include textStyle($sans-bold, 14px, $gray--darkest, bold, 20px);
    margin-top: 48px;
    padding: 0 32px;
    text-align: center;
  }
  &__kpModalContent {
    @include textStyle($sans-reg, 12px, $gray--darkest, normal, 20px);
    padding: 0 32px;
    text-align: center;
    margin-bottom: 0;
  }
}

button.ui.button.popupModal__buttons--button {
  &.green.centered {
    margin-right: 30px;
  }
}

.ui.form .fields .cpstep4__radio .field {
  margin-right: 76px;
  label {
    font-size: 14px;
  }
}