@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.loaderSpinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    width: 100%;

    &__loader {
        width: 48px;
        height: 48px;
        border: 5px solid $gray--darkest;
        border-bottom-color: transparent;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;


        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }
}