@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.relatedCasesList {    
    &__slider {
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        .slick-slider{
           padding: 0 36px;
           .slick-prev.slick-disabled,
           .slick-next.slick-disabled{
               display: block;
               opacity: 0.5;
           }
        }
        .slick-arrow{
            bottom: auto;
            top: 50%;
            margin-top: -12px;
            height: 24px;
            width: 24px;
            background-color: $gray--darkest;
            border-radius: 15px;
            padding: 0;
            font-size: 10px;
            color: $white;
            .arrow{
                margin: 11px 2px 0 0px;
            }

        }
        .slick-prev{
            left: 1px;
            right: auto;
            line-height: 0;
        }
        .slick-next{
            transform: rotate(180deg);
            right: 1px;
            line-height: 0;
        }
        i.icon.arrow{
            &.right:before,
            &.left:before{
                content: "\f104";
            }
        }
    }
    &__tile {
        display: inline-block;
        width: 33.34%;
        white-space: normal; 
        vertical-align: top;
        font-size: 12px;
        font-family: $sans-reg;        
        color: $gray--eclipse;
        &:first-child {
            .icon {
                padding-left: 0;
            }
        }
    }    
}