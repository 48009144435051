@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.emailInputWrapper {
    position: relative;

    &__field {
        width: 100%;
    }

    &__input {
        .ui.input {
            input {
                padding: 15px;
            }
        }

        &__searchIcon.ui.image {
            position: absolute;
            top: 10px;
            bottom: 0px;
            right: 26px;
            cursor: pointer;
            height: 22px;
            margin-right: -10px;
        }
    }

    &__error {
        @include errorMsg;


        p {
            margin-top: 9px;
            margin-left: -163px;
        }

        img.ui.image {
            display: inline-block;
            margin-right: 6px;
            vertical-align: top;
            height: 13px;
        }
    }

    .ui.form .fields {
        margin: 0px;
    }
}