@import "src/global-styles/colors";
@import "src/global-styles/typography";

.treeViewer {
  &__loading {
    font-style: italic;
    color: $gray--darkest;
    padding-left: 28px;
    font-family: $sans-reg;
  }

  &__error {
    color: $bcg-red;
  }

  &__term {
    &__details {
      padding: 10px;
      display: flex;
      align-items: center;
      cursor: pointer;

      &.last-leaf-node {
        padding: 0;

        &.selected,
        &:hover {
          background: $black;

          .treeViewer__term__details__text {
            color: $white;
          }
        }

        .treeViewer__term__details__text {
          padding: 10px 10px 10px 35px;
        }
      }

      &__text {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
        color: $gray--darkest;
        font-family: $sans-reg;
      }
    }

    &__tooltip {
      width: 100%;
      margin: 0;

      .tooltipwrap__popup {
        padding: 10px 15px;
      }

      &__txt {
        font-family: $sans-reg;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 18px;
      }

      &__desciption {
        color: $white;

      }

      &__additional_info {
        color: $notify-warn-highlight;
        font-size: 10px;
        margin-top: 10px;
        display: flex;
        align-items: center;

        >img {
          font-size: 13px;
          margin-right: 5px;
        }
      }
    }

    button {
      border: none;
      background: none;
      text-align: left;
      padding: 0 0 0 10px;
    }

    button.browse {
      padding: 0;
      color: $gray--darkest;
      transition: transform 0.1s ease-out;

      &.browse-open {
        transform: rotate(90deg);

        svg {
          position: relative;
          left: 2px;
        }
      }
    }
  }
}