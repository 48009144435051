@import "src/global-styles/colors";
@import "src/global-styles/typography";

.typeaheadselector {
    &__info-icon {
        width: 16px;
        height: 16px;
    }

    &__wrapper {
        position: relative;
        border: solid 1px $gray--dark;
        border-radius: 4px;
        background-color: $white;
        padding: 9px 6px 0 6px;

        &--full {
            .react-autosuggest {
                &__container {
                    display: block;
                }

                &__input {
                    width: 100% !important;
                }
            }
        }

        .react-autosuggest {
            &__input {
                padding: 0;
                border: 0 !important;

            }

            &__suggestions-container--open {
                overflow-y: initial;
            }
        }

        .ui.form & input {
            border: 0;
            padding: 0;
            padding-left: 0px;
            height: 38px;
            margin-bottom: 9px;
            width: 200px;

            &:focus {
                border: 0;
            }
        }
    }

    &__label__disabled {
        opacity: 0.5;
    }

    &__disabled {
        opacity: 0.5;
        background: $gray--light;
        pointer-events: none;
        user-select: none;

        .ui.form .fields & input {
            background-color: $gray--light;
        }
    }

    &__error {
        border-color: $notify-warn-highlight !important;
    }

    &__selected {
        background-color: $gray--light;
        font-size: 14px;
        padding: 7px 10px;
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
        margin-bottom: 9px;
        border-radius: 5px;

        span {
            padding: 5px 8px 0 9px;
            display: inline-block;
            vertical-align: top;
            font-size: 14px;
            color: $gray--darkest;
            font-family: $sans-bold;
            font-weight: bold;
        }

        .ui.image {
            display: inline-block;
            vertical-align: top;
            width: 29px;
            border-radius: 17px;
            object-fit: cover;
            height: 29px;
        }

        &__crossicon.ui.image {
            margin-top: 6px;
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }

    .react-autosuggest {
        &__container {
            display: inline-block;
            vertical-align: top;
        }

        &__suggestions-list {
            position: absolute;
            left: 0;
            background: $white;
            border: 1px solid $gray--whitish;
            border-radius: 5px;
            z-index: 11;
            max-height: 400px;
            overflow-y: auto;
            width: 100%;
            margin-top: 1px;
            border-top: none;
        }

        &__suggestion {
            &-wrap {
                display: flex;
                font-size: 14px;
                line-height: 24px;
                color: $gray--darkest;
                font-family: $sans-bold;
                padding: 16px 11px 15px 16px;
                border-bottom: 1px solid $gray--whitish;
                justify-content: space-between;
                cursor: pointer;

                span+span {
                    font-size: 12px;
                    font-family: $sans-reg;
                }
            }

            &--highlighted {
                >div {
                    background-color: $black;
                    color: $white;
                }
            }
        }
    }
}