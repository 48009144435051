@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';
.slink {
  display: flex;
  flex: auto;
  align-items: flex-start;
  &__tile {
    display: flex;
    flex: auto;
    align-items: flex-start;
  }

  &__content {
    flex: auto;
  }
  &__title {
    @include textStyle($sans-reg, 14px, $black, bold, 24px);
    min-height: 15px;
    margin-top: -5px;
    word-break: break-word;
  }
  &__link {
    @include textStyle($sans-reg, 14px, $gray--darker, normal, 20px);
    text-decoration: underline;
    word-break: break-all;
  }
  &__image {
    &--link {
      margin-right: 15px;
    }
  }
  &__icon {
    &--link.icon {
      margin-right: 15px;
      border-radius: 5px;
      background-color: $gray--light;
      border: 1px solid $gray--dark;
      box-shadow: unset;
    }
    &--delete {
      cursor: pointer;
    }
    &--delete-white {
      background: $black;
      height: 50px;
      width: 50px;
    }
  }
}
