@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.breadCrumb {
  padding-top: 24px;

  display: inline-block;
  margin-right: 20px;
  .link__home {
    display: inline;
  }
  .home.icon {
    color: white;
    display: inline;
  }
  a.link__home + h1 {
    display: inline;
    margin-left: 5px;
    &:before {
      content: "\/";
      margin-right: 10px;
    }
  }

  &__title {
    margin-top: 20px;
    margin-bottom: 15px;
    color: $white;
    font-size: 14px;
    line-height: 1.71;
    font-family: $sans-reg;
    text-transform: uppercase;
    letter-spacing: 0.8px;
  }
}
