@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';


.kpgridlist__container {
    display: flex;
    flex-wrap: wrap;

    .kpgridlist__element {
        width: calc((100% / 3) - 20px);
        max-width: calc((100% / 3) - 20px);
        box-sizing: border-box;

        &:nth-child(3n-1) {
            margin: 0 30px;
        }
    }

    &--detailedView {
        display: flex;
        flex-flow: wrap;
        margin-bottom: 80px;
        justify-content: space-around;
        row-gap: 20px;

        &--2 {
            gap: 30px;
            justify-content: start;
        }

        & .kpgridlist__element {
            width: 312px;
        }
    }
}