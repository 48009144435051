@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.csdescription {
  padding: 0px;
  padding-top: 10px;
  display: flex;

  &__loading {
    cursor: pointer;
  }

  &__icon-square {
    color: $bcg-green--blue-green;
  }

  &__col-description {
    max-height: 100%;
  }

  &__previewdocvizonly {
    width: 100%;
    height: 100%;

    .card-view-scroll {
      & .docviz-sidebar.col-lg-12 {
        height: calc(100% - 106px);

        & .thumbnailsSection {
          padding: 0px;
        }
      }
    }

    &.restricted {
      .card-view-scroll {
        & .docviz-sidebar {
          height: calc(100% - 150px);
        }
      }
    }


    & .docviz-wrapper {
      margin-bottom: 20px;
      height: 100%;
    }
  }

  &__description {
    p {
      margin: 12px 0;
    }

    ul {
      list-style: disc;
      margin-left: 12px;
      padding: 0 8px 12px;
    }

    li {
      list-style-type: inherit;
      margin-left: 18px;
    }

    ol {
      margin-left: 15px;
      padding: 0px;
    }

    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    padding: 0px;
    word-break: break-word;
  }

  &__col {
    max-width: 90%;
    flex: 1;
    width: calc(100% - 375px);
    padding-right: 20px;
  }

  &__bundlecol {
    max-width: 90%;
  }

  &__image {
    width: 35%;
    margin-left: 2%;
    margin-right: -10px;
  }

  &__pagination {
    cursor: pointer;
  }

  &__preview {
    max-width: 40%;
    width: 375px;
  }

  &__restricted {
    color: $bcg-red;
    margin: 0 5px 10px 5px;
    display: flex;

    img.ui.image {
      padding-right: 8px;
      display: inline;
    }
  }
}