@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/_mixins";

.contactsSection {
  width: 100%;

  .display {
    padding: 6px 0px;

    &__items {
      &__name {
        width: 43%;
        display: inline-block;
        vertical-align: top;

        &--tag {
          border-radius: 20px;
          background-color: $gray--darkest;
          opacity: 0.5;
          padding: 5px 12px;
          margin-left: 8px;
          @include textStyle($sans-light, 12px, $white, normal, 24px);
        }
      }

      &__position {
        vertical-align: top;
        width: 42%;
        display: inline-block;
        padding-right: 20px;
      }

      &__hours {
        vertical-align: top;
        width: 10%;
        display: inline-block;

        &--heading {
          width: 15%;
          display: inline-block;
        }
      }
    }
  }

  &__headwrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  &__dropdown {
    .ui.selection.dropdown {
      min-height: 5px;
      padding: 7px;
      padding-right: 30px;
      color: $gray--darkest;
      font-size: 12px;
      font-family: $sans-bold;

      .menu {

        >.item,
        >.active.item {
          font-size: 12px;
          font-family: $sans-reg;
          font-weight: normal;
        }
      }

      >.dropdown.icon {
        padding: 8px;
      }
    }
  }

  &__heading {
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 12px;
    line-height: 20px;
    caption-side: top;
  }

  &__headers {
    color: $gray--brownish;
    line-height: 20px;
    font-family: $sans-reg;
    font-size: 12px;

    &__tooltip {
      padding-left: 5px;

      img {
        width: 12px;
      }

      &__text {
        display: block;
        width: 150px;
      }

      .tooltipwrap {
        position: relative;
      }

      .tooltipwrap__popup.content.place-left {
        position: absolute;
        left: -204px !important;
        top: -46px !important;
      }
    }
  }

  &__data {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 12px;
    line-height: 20px;
    max-height: 526px;
    overflow-y: auto;
    overflow-y: overlay;

    &--name {
      font-family: $sans-bold;
      line-height: 24px;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    li:nth-child(even) {
      background: rgba(242, 242, 242, 0.65);
    }
  }
}

.otherContactsModal {
  &__heading {
    color: $gray--darkest;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.8px;
  }

  &__data {
    max-height: 114px;
  }
}

.caseListing {
  &--listing {
    padding: 10px 35px;
    border-radius: 6px;
    background-color: $white;
    @extend %shadow--white;
  }

  &__heading {
    font-size: 14px;
    padding: 20px 0;

    .ui.label.label__count {
      background-color: $bcg-green--blue-green;
      color: $white;
      margin-left: 10px;
    }
  }

  &__dropdown {
    .dropdownCustom {
      display: inline;
      margin-left: 10px;
    }

    .ui.selection.dropdown {
      display: inline;
    }
  }

  &__data {
    max-height: 380px;
  }
}