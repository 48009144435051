@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.faceCardUserProfile {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-direction: column;
    position: absolute;
    top: 0;
    width: 100%;
    // transition: 0.4s;

    &__image {
        width: 140px;
        border-radius: 50%;
        // margin-right: 12px;
    }

    &__name {
        color: $white;
        font-size: 20px;
        font-family: $sans-bold;
        position: relative;
        top: 7px;
        transition: font-size 0.4s;
        transition: margin-left 0.4s;
        transition: top 0.6s;
    }

    &__scrolled {
        flex-direction: row;
        margin-left: 35px;

        .faceCardUserProfile__image {
            width: 32px;
            transition: width 0.8s;
        }

        .faceCardUserProfile__name {
            font-size: 16px;
            top: 0px;
            margin-left: 12px;
        }
    }
}