@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.thumbsupdown {
  &__wrap{
    background: $gray--light;
    display: inline-block;
    padding: 3px 0 4px 0;
    border-radius: 10px;
    color: $gray--dark;
  }
  &__img{
    &.ui.image{
      display: inline;
      width: 18px;
      height: 16px;
      margin:0px 0 0;
      vertical-align: top;
      padding: 2px 9px;
      box-sizing: content-box;
      cursor: pointer;
    }
    &--active{
      color: $bcg-green;
    }
    &--topmargin{
      &.ui.image{
        margin-top: 2px;
      }
    }
  }
}
.questionare{
  width: 328px;
  &__wrap{
   padding: 17px 0 0;
  }
  h5{
    font-size: 14px;
    line-height: 20px;
    font-family: $sans-bold;
    padding-right: 30px;
  }
  .ui.form{
    .textareacounter{
      &__textarea{
        height: 85px;
        padding-bottom: 11px;
        font-size: 12px;
        line-height: 20px;
      }
      span.counter{
        display: none;
      }
    }
  }
  &__buttonwrap{
    border-top: 1px solid $gray--dark;
    margin-top: 16px;
    padding-top: 19px;
    text-align: right;
    .ui.button.green.green{
      font-size: 10px;
      height: 24px;
      line-height: 9px;
    }
  }
  
  
  

  &__checkbox{
    &.ui.checkbox{
      label{
        font-size: 14px;
        line-height: 24px;
        color: #333;
        font-family: $sans-reg;
      }
    }
  }
  &__crossicon{
    &.ui.image{
      width: 16px;
      height: 16px;
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }
}