@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.tx {
    &__modal {
        width: 90%;
        overflow: initial;
        white-space: nowrap;
        height: 98%;
        padding: 18px 0 0;
        display: flex;
        flex-direction: column;
        border: none;

        &--full {
            width: 99%;
        }
    }

    &__midsection {
        height: 100%;
        overflow-y: auto;
        position: relative;
    }

    &__suggestions {
        float: right;
        height: 100%;
        width: 284px;
        box-shadow: -2px 8px 18px 0 rgba(0, 0, 0, 0.31);
        z-index: 2;
        padding: 16px 11px;
        white-space: normal;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        overflow-y: auto;
        background-color: $white;

        ul {
            &>.txbase__suggestions__tooltip {
                &>.tooltipwrap__icon>.txbase__suggestions__tooltip__disabled>.tx__suggestionslistitem {
                    background-color: $gray--light--disable;
                    color: $gray--dark;
                }
            }
        }
    }

    &__suggestionsHeading {
        font-size: 14px;
        font-family: $sans-bold;
        line-height: 20px;
        letter-spacing: 1.5px;
        color: $gray--darkest;
        margin: 3px 0 14px 4px;
    }

    &__suggestionslist {
        li {
            margin-bottom: 8px;

            &[index=fa55a41b-5ed8-4966-a630-b482683b2b00] {
                display: none;
            }

            &.hideSuggestionTags {
                display: none;
            }

            .tx__suggestionslistitem {
                border-radius: 19px;
                background-color: #F2F2F2;
                padding: 6px 32px 6px 11px;
                line-height: 24px;
                font-family: $sans-reg;
                font-size: 14px;
                display: inline-block;
                position: relative;
            }

            img.ui.image {
                display: inline-block;
                margin-left: 9px;
                position: absolute;
                right: 9px;
                top: 12px;
                cursor: pointer;

                &.txbase__plusicon {
                    top: 4px;
                    right: 2px;
                }
            }

            .tooltipwrap>.tooltipwrap__icon>.txbase__poly-img {
                position: static;
                margin: 0 10px 3px 0;
                cursor: default;
            }
        }


    }

    &__wrap {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: row;
        overflow-y: auto;
        padding: 0 20px;
    }

    &__topbar {
        background-color: $gray--light;
        padding: 11px 28px 1px;
        white-space: normal;
        min-height: 60px;
        max-height: 108px;
        overflow-y: auto;
        flex: none;

        li {
            display: inline-block;
            background-color: $white;
            height: 38px;
            line-height: 38px;
            padding: 0 11px;
            border-radius: 4px;
            margin-right: 10px;
            margin-bottom: 10px;
            font-size: 14px;

            img.ui.image {
                display: inline;
                margin-left: 10px;
                cursor: pointer;
            }

            &.txbase__autoTagged {
                background-color: $autoTaggerColor;
            }

            &>.tooltipwrap>.tooltipwrap__icon>.txbase__poly-img {
                margin: 0 10px 3px 0;
                cursor: default;

                &.txbase__iIcon-img {
                    margin: 0 6px 3px 0;
                }
            }
        }
    }

    &__modalheader {
        padding: 0 20px 18px 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__modalheader--withsearch {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
    }

    &__heading {
        display: inline-block;
        vertical-align: top;
        margin: 0;
        font-size: 18px;
        line-height: 22px;
        color: $gray--darkest;
        font-family: $sans-bold;
        font-weight: 800;
        white-space: normal;
        margin-right: 70px;
    }

    &__modalcross {
        vertical-align: top;
        float: right;
        margin-top: -7px;
        cursor: pointer;
    }

    &__tabWrapper {
        height: 100%;
        overflow-y: auto;

        &-havesuggestions {
            width: calc(100% - 282px);
        }
    }

    &__tabs {
        margin-top: 0;
        min-height: auto !important;
        height: 100%;

        .ui.secondary.pointing.menu {
            margin-left: 0;
            width: auto;
            height: auto;
            margin-bottom: 0;
            border-bottom: 2px solid $gray--light;

            .item,
            .item:hover {
                display: inline-block;
                height: auto;

                padding: 15px 48px 11px;
                margin: 0 10px;

                .item-style {
                    font-size: 14px;
                    line-height: 20px;
                    text-transform: uppercase;
                    height: auto;
                    letter-spacing: 1.5px;
                    margin-bottom: 0;
                    position: relative;
                    padding-right: 14px;

                    &::after {
                        position: absolute;
                        right: -6px;
                        top: 7px;
                        transform: rotate(90deg);
                        content: '\25BA';
                        color: $gray--darkest;
                        zoom: 0.82;
                        width: 8px;
                        height: 6px;
                    }
                }

                .disable {
                    &::after {
                        color: $gray--dark;
                    }
                }
            }
        }

        .ui.tab {
            height: calc(100% - 53px);
            margin: 0;
            width: auto;
            padding: 0;
        }
    }

    &__columnwrap {
        overflow-y: auto;
        display: flex;
    }

    &__column {
        width: 220px;
        position: relative;
        overflow-y: auto;
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        border-right: 2px solid $gray--light;

        ul {
            width: 100px;
            height: 50px;
        }

        li {
            font-size: 14px;
            line-height: 20px;
            padding: 10px 24px 10px 8px;
            cursor: pointer;
            color: $gray--darkest;
            position: relative;

            &.havechild::after {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                content: '\25BA';
                color: $gray--dark;
                zoom: 0.82;
            }

            &.hideTag {
                display: none;
            }

            &:first-child {
                margin-top: 8px;
            }

            &:hover,
            &.hovered {
                background-color: $gray--light;
            }

            &.selected {
                background-color: $gray--light;
                color: $bcg-green--light-blue-green;
                font-weight: 800;
                font-family: $sans-bold;
            }

            &>ul {
                display: none;
                position: absolute;
                left: 100%;
                top: 0;
            }

            &:hover ul {
                display: block;
            }

            &:first-child ul {
                display: block;
            }
        }

        &-listClass {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &>.tooltipwrap>.tooltipwrap__icon>.tx__column-polyHImg {
                width: 20px;
                height: 20px;
            }
        }

        &-suggestionName {
            width: calc(100% - 20px);
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 12px 30px 13px;
        background: $gray--darkest;

        &__cta.ui.button {
            margin-left: 20px;
        }

        &__left {
            display: inherit;
        }
    }
}

.polyHRotateImage {
    transform: rotate(180deg);
}

.polyHIconMargin {
    margin: 0 6px 0 0;
}