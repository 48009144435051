@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.layout {
  &__creation {
    padding: 130px 0 30px;
  }
  &__pagestyle {
    margin-top: -60px;
    padding: 30px;
    position: relative;
    z-index: 1005;
    min-height: calc(100vh - 172px);
    &:after {
      background: url(../../assets/images/art-work.svg) no-repeat;
      content: "";
      position: absolute;
      width: 542px;
      height: 401px;
      bottom: 40px;
      right: 147px;
    }
  }
  &__textblock {
    padding: 30px 0;
  }
  &__creationblock {
    width: 100%;
    @media (min-width: map-get($grid-breakpoints, xxl)) {
      max-width: 1150px;
    }
  }
  &__creationbtn {
    width: 46%;
    display: inline-block;
    margin: 0 30px 30px 0;
    position: relative;
    z-index: 1;
    max-width: 526px;
  }
  &__creationbtn--hidden {
    display: none;
  }
  &__text {
    font-size: 34px;
    line-height: 50px;
    font-family: $serif-bold;
    color: $gray--darkest;
    padding-top: 15px;
  }
  &__creationtxt {
    flex: 1;
  }
  &__creationlink {
    padding: 20px 20px 20px 30px;
    color: $gray--darkest;
    background: $gray--light;
    border-radius: 6px;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.8px;
    margin-right: 30px;
    font-family: $sans-bold;
    display: flex;
    align-items: center;
  }
  &__creationinfoicon {
    width: 16px;
    height: 16px;
  }
  &__creationlink:hover {
    color: $gray--darkest;
  }
  &__creationlink--disabled {
    opacity: 0.7;
  }
  &__btn-tag {
    border: none;
    text-align: left;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
}
