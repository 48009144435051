@import "src/global-styles/colors";
@import "src/global-styles/typography";

// for the font style
@mixin textStyle($family, $size, $color, $weight: normal, $lineHeight: 1.71) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  font-stretch: normal;
  font-style: normal;
  line-height: $lineHeight;
  letter-spacing: normal;
  color: $color;
}

@mixin getLayoutInnerPadding {
  padding-left: 134px;
  padding-right: 134px;
  max-width: 1567px;
}

@mixin borderBox {
  border: map-get($pixels, "two") solid $bcg-green--light;
  @extend %shadow--3;
  border-radius: 8px !important;
}

@mixin errorMsg {
  margin-top: 2px;
  font-size: 12px;
}

@mixin buttonFooter {
  font-family: $sans-reg;
  font-size: 12px !important;
  line-height: 20px;
  font-weight: bold;
  text-align: center;
  color: $white !important; // important because of .ui.button
  border-radius: 5px !important;
  letter-spacing: 0.8px;
  background-color: transparent !important;
  border: 1px solid $white !important;
  padding: 8px 25px;
}

@mixin buttonFooterCTA {
  @include buttonFooter;
  background-color: $bcg-green--light-blue-green !important;
  border: 0 !important;
  padding: 8px 35px;
}

@mixin buttonBlack($color, $bgcolor, $padding) {
  background-color: $bgcolor;
  text-transform: uppercase;
  border-radius: 5px;
  color: $color;
  padding: $padding;
  display: flex;
  align-items: center;
}

@mixin borderedField() {
  border: 1px solid $gray--dark;
  border-radius: 7px;
  padding: 20px 10px;
}

@mixin hollowCheckbox($height: 20px, $borderRadius: 3px, $fontSize: 14px) {
  content: "\2713";
  border: 1px solid $gray--blackish;
  border-radius: $borderRadius;
  width: $height;
  height: $height;
  font-size: $fontSize;
  padding-left: 3px;
  padding-right: 3px;
  margin-right: 10px;
  vertical-align: middle;
  color: transparent;
  transition: .2s;

}

@mixin checkedCheckbox($height: 20px, $borderRadius: 3px, $fontSize: 14px) {
  @include hollowCheckbox($height, $borderRadius, $fontSize);
  background-color: $bcg-green--light-blue-green;
  border-color: $bcg-green--light-blue-green;
  color: $white;
}

@mixin cssHollowCheckbox() {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 3px;
  background-color: $white;
  border-color: $checkboxgray;
  margin-top: 5px;
}

@mixin cssTickMark($fillColor: $gray-filler) {
  content: "";
  position: absolute;
  left: 5px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid $white;
  background-color: $fillColor;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  border-radius: 0;

}

@mixin textPill() {
  border-radius: 20px;
  font-family: $sans-reg;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  padding: 6px 10px;
  background-color: $gray--light;
  color: $gray--darkest;
}

@mixin darkTextPill() {
  @include textPill;
  background-color: $gray--darkest;
  color: $white;
  padding: 5px 10px;
  font-size: 14px;
}

@mixin multilineellipsis($lines, $lineHeight: 20px) {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: $lineHeight;
  max-height: #{$lines * $lineHeight};
  /* Fallback for non-webkit */
  line-clamp: $lines;
  -webkit-line-clamp: $lines;
  -moz-line-clamp: $lines;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
}

@mixin alignCenterViaAbsolute() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin disabledLink() {
  cursor: default;
  pointer-events: none;
}

@mixin beyondLayout($width) {
  margin-left: -$width;
  margin-right: -$width;
  padding-left: $width;
  padding-right: $width;
}

%item-active {
  border: 3px solid $bcggreenfive;
  // border-top: 3px solid $bcggreenfive;
  border-right: 1px solid $bcggreenfive;
  border-left: 1px solid $bcggreenfive;
  background-color: $white;
  color: $bcggreenfive;
  padding-left: 41px;
  border-bottom-color: transparent;
  font-family: $sans-reg;
  margin-left: 0px;
  margin-right: 0px;
  font-weight: normal;
}