@import "src/global-styles/colors";
@import "src/global-styles/typography";

.additionalinfo {
  background-color: $black;
  padding: 5px 10px;
  text-transform: uppercase;
  border-radius: 5px;

  color: $white;

  .ui.button {
    padding: 0;
    color: $white;
    background-color: $black;

    &:hover {
      background-color: transparent;
      color: $white;
    }
  }

  img.ui.image,
  label {
    display: inline-block;
  }

  label {
    margin-left: 10px;
    margin-top: 5px;
    font-size: 12px;
    letter-spacing: 0.8px;
    line-height: 15px;
    font-family: $sans-reg;
    cursor: pointer;
  }
}