@import "src/global-styles/colors";
@import "src/mixins/mixins";

.contribute-knowledge-tab {
  position: relative;

  &__content {
    @include getLayoutInnerPadding();
    background-color: $gray--lightest;
    padding-top: 20px;
    padding-right: 0px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    padding-right: 97px;
  }

  &__bundle-button {
    border-radius: 5px;
    padding: 10px 30px;
    background-color: $bcg-green--light-blue-green;
    font-size: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    cursor: pointer;
    color: $white;

    &:hover {
      color: $white;
    }
  }

  &__filters {
    padding-bottom: 22px;
  }

  &__category {
    margin-bottom: 12px;
  }

  &__category-title {
    font-size: 12px;
    color: $gray--darkest;
    font-weight: 700;
    letter-spacing: 0%;
    line-height: 20px;
    font-family: $sans-reg;
    vertical-align: middle;

    &-text {
      text-transform: uppercase;
    }
  }

  &__items-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    position: relative;
  }

  &__item-box {
    background: $white;
    padding: 8px;
    border: 1px solid $gray--light;
    border-radius: 8px;
    gap: 16px;
    width: 315px;
    height: 99px;
    transition: all 0.3s ease-out;
    position: relative;
    overflow: hidden;

    &:hover {
      box-shadow: 0px 0px 8px 0px #21BF61;
      height: 155px;
      margin-top: -28px;
      margin-bottom: -28px;
      z-index: 1;
      padding-bottom: 12px;
    }

    &:hover .contribute-knowledge-tab__item-desc {
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
      display: block;
    }

    &:hover .contribute-knowledge-tab__item-button {
      display: flex;
    }
  }

  &__item-title {
    font-size: 14px;
    color: $gray--darkest;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 20px;
    font-family: $sans-reg;
    margin-bottom: 6px;

  }

  &__item-desc {
    font-size: 14px;
    color: $gray--brownish;
    font-family: $sans-reg;
    font-weight: 400;
    letter-spacing: 0px;
    @include multilineellipsis(3);
  }

  &__item-button {
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 8px;
    position: absolute;
    bottom: 8px;
    left: 24%;

    &--file {
      width: 80px;
      height: 30px;
      border-radius: 5px;
      border: 2px solid #3FAD93;
      background-color: $white;
      color: $gray--darkest;
      font-family: $sans-reg;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.8px;
      text-align: center;
      text-transform: uppercase;
      padding: 4px;
      cursor: pointer;

      &:hover {
        color: $gray--darkest;
      }
    }

    &--url {
      width: 80px;
      height: 30px;
      border-radius: 5px;
      background-color: $bcg-green--light-blue-green;
      color: $white;
      font-family: $sans-reg;
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.8px;
      text-align: center;
      text-transform: uppercase;
      padding: 4px;
      cursor: pointer;

      &:hover {
        color: $white;
      }
    }

  }
}