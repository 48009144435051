@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.adminhistorymodallist {
  display: flex;
  justify-content: space-between;
  
  &__value.adminhistorymodal__column {
    word-break: break-word;
    letter-spacing: 0;
    line-height: 20px;
    font-family: $sans-reg;
  }
  &__fileicon {
    margin-right: 6px;
    height: 22px;
    width: 23px;
  }
  &__filetitle {
    margin-top: -2px;
    @include multilineellipsis(3);
  }
  &__personimage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  &__person {
    display: flex;
    flex-direction: column;
    margin-left: 13px;
    color: $gray--darkest;
  }
  &__personname {
    font-family: $sans-bold;
    line-height: 16px;
    padding-bottom: 2.85px;
  }
  &__personrole {
    font-size: 12px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}