// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.KBStep3 {
  border-radius: 13px 13px 0 0;
  background-color: $gray--lightest;

  &__ipafpa {
    border: 1px solid $checkboxgray;
    border-radius: 7px;
    padding: 20px;
  }

  &_tx_subheading {
    font-size: 12px;
  }

  &_tx_info {
    font-size: 12px;
    color: $dark-orange;

    &icon.ui.image {
      display: inline-block;
      margin-left: 10px;
      margin-right: 7px;
      transform: rotate(180deg);
      height: 13px;
    }
  }

  &__inner {
    padding-bottom: 25px;
    margin: 0 30px;
  }

  .pa-custodian-wrap {
    margin-top: 20px;
  }

  form {
    counter-reset: input-counter;
    margin-top: 30px;

    .field {
      position: relative;

      .pa-custodian--error,
      .pa-custodian {
        border: solid 1px transparent;
        border-radius: 4px;
        padding: 0 55px 0 6px;
      }

      .pa-custodian--error,
      .customhighlight>div,
      .customhighlight div.rich__editor {
        border-color: $notify-warn-highlight;
      }

      label {
        height: 24px;
        @include textStyle($sans-bold, 14px, $gray--darkest, bold);
      }

      >label {
        &:before {
          counter-increment: input-counter;
          content: counter(input-counter) ".";
          position: absolute;
          top: -5px;
          left: -20px;
          width: 24px;
          height: 22px;
          @include textStyle($sans-bold, 20px, $gray--dark, bold);
        }

        span {
          float: right;
          margin-top: 1px;
          height: 20px;
          @include textStyle($sans-reg, 14px, $gray--darkest, normal, 20px);
        }

        .optional-text {
          float: none;
          color: $gray--dark;
          font-family: $sans-bold;
        }
      }

      input,
      .selection {
        padding-left: 5px !important;
        height: 56px;
        border-radius: 4px;
        font-family: $sans-reg !important;
        border: solid 1px $gray--dark !important;
        color: $gray--darkest;

        &:focus {
          border: solid 1px $bcg-blue--light !important;
        }
      }

      .highlight input {
        border-color: $notify-warn-highlight !important;
      }

      .selection {
        .label {
          display: inline-flex;
          border-radius: 4px;
          height: 38px;
          box-shadow: unset;
          margin: 5px 5px 5px 0;
          background-color: $gray--light;
          @include textStyle($sans-reg, 16px, $gray--darkest, normal, 24px);

          .user {
            font-size: 1.75em;
            margin-right: 0.4em;
          }

          .delete {
            display: none;
          }

          &__image {
            width: 28px !important;
            height: 28px !important;
          }

          &__content {
            margin-left: 5px;

            &__title {
              margin-top: 8px;
              @include textStyle($sans-bold, 14px, $gray--darkest, bold, 12px);
            }

            &__subtitle {
              @include textStyle($sans-reg, 12px, $gray--darkest, normal, 20px);
            }
          }
        }

        .dropdown {
          display: none;
        }
      }
    }
  }
}