@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.additionalurl {
  margin: 5px 0;
  padding: 17px;
  border: 1px solid $gray--dark;
  background-color: $white;
  &:hover {
    box-shadow: 1px 0px 5px $gray--dark;
    cursor: pointer;
  }
  &__heading {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 17px;
  }
}
