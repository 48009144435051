// Import
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.footer-workflow {
  background-color: $gray--darkest;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 60px;
  margin: 0;
  padding: 5px 20px;
  border-bottom: 2px solid #707070;

  &:empty {
    display: none;
  }

  .tooltipwrap {
    margin-top: 10px;
    margin-right: 16px;

    &.publish-btn {
      margin-top: 0px;
      display: flex;
      margin-right: 0px;
    }
  }

  .ui.button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link.button {
    margin: 5px !important;
    @include buttonFooter;
  }

  &__btn.ui.button {
    margin: 5px !important;
    @include buttonFooterCTA;
    line-height: 22px;
  }

  &__button--back.button,
  &__button--next.ui.button {
    padding: 8px 15px;
  }

  &__button--publish.ui.button:disabled {
    pointer-events: auto !important;
  }

  &__button {
    &--fullsize {
      width: 187px;
    }
  }

  &.footer-sticky {
    position: sticky;
    bottom: 0;
    justify-content: space-between;
    z-index: 10;
  }

  &.footer-top {
    height: auto;
    background: none;
    border-bottom: none;
    z-index: 11;

    .footer-workflow__link.button {
      color: $black !important;
      border: solid 2px $black !important;
      line-height: 26px;
      margin-right: 15px !important;

      @media (max-width: 1400px) {
        width: 175px;
      }
    }

    #btn-publish {
      @media(max-width: 1400px) {
        width: 90px;
      }
    }
  }
}