@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.caseversionlisting {
    display: flex;
    justify-content: flex-start;

    &__column {
        word-break: break-word;
        letter-spacing: 0;
        line-height: 20px;
        flex: 1 1 140px;
        color: $gray--darkest;
        font-size: 14px;
        font-family: $sans-bold;

        &--date,
        &--changed_by {
            max-width: 140px;
            flex: 0 0 140px;
        }

    }

    &__message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        height: 100%;

        p {
            font-size: 20px;
            color: $gray-500;
            font-family: $sans-bold;
        }
    }

    &__columns {
        display: flex;
        padding: 16px 20px;
        justify-content: space-between;
        background-color: $white;
    }

    &__value {
        word-break: break-word;
        letter-spacing: 0;
        line-height: 20px;
        flex: 1 1 140px;

        &--date,
        &--changed_by {
            max-width: 150px;
            flex: 0 0 140px;
            color: $black;
        }

        &--updatedFields {
            display: inline-block;
            color: $gray--light;
            max-width: 500px;
        }
    }

    &__inline {
        border-radius: 13px;
        background: $gray--darkest;
        color: $gray--light;
        font-family: $sans-reg;
        font-size: 14px;
        padding: 1px 12px 1px 12px;
        margin: 4px;
    }

    &__center {
        text-align: center;
    }

    &__tablecontent:nth-child(odd) {
        background-color: $gray--light;
        padding: 14px 20px;
    }

    &__tablecontent:nth-child(even) {
        background-color: $white;
        padding: 14px 20px;
    }

    &__tablewrap {
        max-height: calc(100vh - 127px);
        overflow: auto;
    }
}