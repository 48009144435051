@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";
@import "src/mixins/mixins";

.mycases{
    border-bottom: 1px solid $checkboxgray;
    margin-bottom: 67px;
    &:last-child {
        border-bottom: none;
    }
    &__left,
    &__right {
        width: 50%;
        display: inline-block;
        vertical-align: top;
    }
    &__right {
        padding: 18px 17px 0 22px;
        h5 {
            font-size: 14px;
            color: $gray--darkest;
            line-height: 24px;
            margin-bottom: 9px;
        }
        .textareacounter__textarea {
            height: 66px;
            line-height: 22px;
        }
        .title__cta.ui.button.green {
            height: 25px;
            line-height: 25px;
            padding-top: 0;
            padding-bottom: 0;
            margin-top: 9px;
            margin-bottom: 16.2px;
        }
    }
    &__taxonomy{
        display: flex;
        li{
            margin-right: 52px;
        }
    }
}
