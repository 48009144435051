.WorkflowWarningSteps {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    margin-bottom: 20px;

    &__error-btn {
        &.ui.button {
            background-color: #f2f2f2;
            color: #333333;
            font-size: 14px;
            width: 180px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 7px;
            border-radius: 6px;
            height: 36px;
            font-weight: bold;
        }
    }

    &__divider {
        width: 200px;
        display: inline-block;
        height: 1.3px;
        background: #d4d4d5;
    }

    &__success-btn {
        &.ui.button {
            background-color: #ffffff;
            color: #333333;
            font-size: 14px;
            width: 180px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            padding: 7px;
            height: 36px;
            font-weight: bold;
            cursor: default;
        }
    }
}