@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.customizedCard {
  &.ui.card.cardhover
  {
  border-radius: 7px;
  border: solid 1px $gray--dark;
  margin-right: 27px;
  margin-top: 18px;
  box-shadow: 0px 1px 3px 0px transparent, 0px 0px 0px 1px transparent;
  width: 258px;
  
  &:hover
  {
     box-shadow: 1px 0px 5px $gray--dark;
     transform: none;  
     border:1px solid transparent;
    }
  }
  &__profile {
    .user.icon {
      color: $bcg-green;
      min-width: 40px;
      min-height: 40px;
      font-size: 2rem;
    }
    margin-top: -2px;
    float: left;
    object-fit: contain;
    &__img {
      border-radius: 50%;
      width: 40px;
      height: 40px;
    }
  }

  &__mail {
    margin-top: 43px;
    float: left;
    margin-left: -30px;
    color: $gray--dark;
    text-decoration: none;
    object-fit: contain;
    &:hover {
      color: $bcg-green--blue-green;
    }
  }
  &__alumni {
    margin-top: 40px;
    float: left;
    margin-left: -40px;
    font-size: 12px;
    line-height: 20px;
    color: $gray--brownish;
  }
  &__name {
    font-family: $sans-bold;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: $black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 51px;
  }
  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-left: 51px;
    font-family: $sans-reg;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $black;
  }
  &__location {
    font-family: $sans-reg;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $gray--darker;
    margin-left: 51px;
  }
}
