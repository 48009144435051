// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.genaibutton {

  background: linear-gradient(90deg, #5D289B 0%, #CD6700 100%);
  border: 1px solid transparent;
  outline: none;
  font-size: 16px;
  font-family: $sans-reg;
  line-height: 16px;
  text-align: left;
  text-decoration-skip-ink: none;
  border-radius: 5px;
  padding: 7px 10px;
  cursor: pointer;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: white;
    border-radius: 5px;
  }

  &::after {
    content: attr(data);
    background: linear-gradient(to right, #5D289B, #CD6700);
    background-clip: text;
    color: transparent;
    position: relative;
  }

  &__wrap {
    position: absolute;
    right: 32px;
    top: -5px;

    &--description {
      top: -33px;
      right: 0;
    }
  }

  img.ui.image {
    display: inline-block;
    vertical-align: top;
    margin: -1px 7px 0 0;
  }

  &__withCounter {
    img {
      bottom: 1px;
    }
  }

  &__counterText {
    color: $white;
    line-height: 5.5px;
    font-size: 9px;
    font-family: $sans-reg;
  }

  &.disable {
    background: linear-gradient(90deg, #b1b1b1 0%, #b1b1b1 100%);

    &::after {
      background: linear-gradient(to left, #b1b1b1, #b1b1b1);
      background-clip: text;
    }
  }
}

.genaipopup {
  position: absolute;
  top: 0;
  box-shadow: 0 2px 12px 0;
  // box-shadow: 1px 1px 1px 1px $notify-warn-highlight;
  border-radius: 8px;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
  transition: opacity 0.5s, visibility 0.5s;
  opacity: 1;
  width: 400px;
  left: calc(100% + 20px);
  z-index: 5;
  background: $white;
  overflow: hidden;
  padding: 16px 16px 0px;

  &__hide{
    display: none;
  }

  &__heading {
    font-size: 16px;
    line-height: 24px;
    font-family: $sans-bold;
    color: $gray--darkest;
    margin: 0;

    &--icon.ui.image {
      margin-right: 10px;
      display: inline-block;
      vertical-align: top;
      margin-top: 3px;
    }

    &::after {
      content: attr(data);
      background: linear-gradient(to right, #5D289B, #CD6700);
      background-clip: text;
      color: transparent;
      position: relative;
    }
  }

  &__subtext {
    font-size: 12px;
    line-height: 16px;
    font-family: $sans-reg;
    color: #323232;
    margin: 10px 0;
  }

  &__suggestions {
    margin: 10px 0;
    padding-left: 18px;

    &--ele {
      margin-bottom: 10px;
      list-style-type: auto;
      font-size: 14px;
      line-height: 16px;
      font-family: $sans-reg;
      color: #0A477D;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__suggestionsdesc {
    margin: 10px 0;

    &--ele {
      font-size: 14px;
      line-height: 16px;
      font-family: $sans-reg;
      color: #0A477D;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__descbuttons {
    display: flex;
    justify-content: center;
    margin: 15px 0 20px;


    &--ele {
      cursor: pointer;
      margin: 0 1px;
      border: 1px solid transparent;
      height: 32px;
      width: 32px;
      display: inline-block;
      vertical-align: top;
      line-height: 30px;
      text-align: center;

      &[disabled] {
        pointer-events: none;
        opacity: 0.4;
      }
    }

    &--prev {
      transform: rotate(180deg);
    }

    &--selected {
      border-color: #197A56;
    }
  }

  &__crossicon.ui.image {
    position: absolute;
    right: 16px;
    top: 13px;
    width: 8px;
    cursor: pointer;
  }

  &__feedback {
    padding: 10px 16px;
    text-align: right;
    border-top: 1px solid $gray--dark;
    margin: 0 -16px;
  }

  &__feedback-heading {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 22px;
    margin-right: 10px;
  }

  &__rai-policy{
      color: $gray--darkest;
      text-decoration: underline;
      font-family: $sans-reg;
      font-size: 14px;
      letter-spacing: 0.8px;
      line-height: 22px;
      margin-right: 108px;
      &:hover {
        text-decoration: underline;
      }
  }

  &__feedback-thumbs {
    margin-left: 10px;
  }
}