@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.kpList {
  &__heading {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0;
    display: inline-block;
    text-transform: uppercase;

    &--withContent {
      @include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px);
      margin-bottom: 16px;
      display: block;
    }
  }

  &__icon {
    color: $gray--dark;
    font-size: 16px;

    &-bookmark {
      width: 13px;
    }
  }

  &__preview--icon {
    margin-top: 2px;
  }
}