@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.relatedCasesWrapper {
  background-color: $gray--lightest;
  .tabs {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0px;
    padding-right: 12px;
    &__container {
      min-height: unset;
    }
    div.ui.secondary.pointing.menu {
      margin-left: 0;
      margin-right: 0;
      .item {
        padding: 13px 0px;
        margin-right: 15px;
      }
    }
    div.ui.bottom.attached {
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      margin-top: -10px;
      background-color: $gray--lightest;
    }
  }
}