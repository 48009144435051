// import color & typography
@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.knowledgepage {
  &__isrestricted {
    display: flex;
    margin-top: 18px;
    margin-bottom: 22px;

    .ui.form & .field {
      margin-right: 42px;
    }
  }

  &__leftsec,
  &__rightsec {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 500px);
  }

  &__leftsec {
    padding-right: 20px;
    position: relative;
    padding-top: 3px;

    &--leftSecHeight {
      min-height: 964px;

      @media screen and (max-width: 2100px) {
        min-height: 1033px;
      }

      @media screen and (max-width: 1800px) {
        min-height: 1033px;
      }

      @media screen and (max-width: 1600px) {
        min-height: 964px;
      }

      @media screen and (max-width: 1400px) {
        min-height: 890px;
      }

      @media screen and (max-width: 1300px) {
        min-height: 808px;
      }
    }
  }

  &__rightsec {
    width: 500px;
    position: absolute;
    height: 100%;

    &>.KPSideBar__wrap {
      height: calc(100% - 105px);

      &>.KPSideBar__preview {
        height: 100%;
        background: none;

        &>.docviz-wrapper {
          height: 100%;
        }
      }
    }

    &--docvizSec {
      position: static;
    }
  }

  &__width-65 {
    width: 50%;
  }

  &__width-35 {
    width: 50%;
  }

  &__requiredLabel {
    position: absolute;
    right: 20px;
    top: 1px;
    @include textStyle($sans-reg, 14px, $gray--darkest, 400);
  }

  .layout &__wrap {
    padding: 25px 40px 40px;
  }

  &__wrap {

    counter-reset: input-counter;
    border-radius: 13px 13px 0 0;

    position: relative;

    .ui.form & .fields {
      position: relative;
      padding-left: 20px;
      margin-bottom: 30px;

      .disabled.field {
        opacity: 0.5;

        &>label {
          opacity: 1;
        }

        .input {
          opacity: 1;
        }

        input {
          background-color: $gray--light;
        }
      }

      input {
        border: 1px solid $gray--dark;
        padding: 18px 10px;
      }

      .peopleselector__wrapper {
        padding: 9px 10px 0 10px;

        input {
          padding: 0;
          height: 43px;
        }
      }

      .ui.dropdown {
        padding: 20px 10px;
        width: 256px;
        border: 1px solid $gray--dark;
        border-radius: 4px;
        background-color: $white;

        .icon {
          top: 15px;
        }
      }

      .customhighlight>div,
      .customhighlight>div>div,
      .customhighlight input {
        border-color: $notify-warn-highlight !important;
      }

      .field>label {
        &.label__disabled {
          display: inline-block;
          vertical-align: top;
        }

        @include textStyle($sans-reg, 14px, $gray--darkest, bold);

        &.label__disabled {
          opacity: 0.5;
        }

        &:before {
          counter-increment: input-counter;
          content: counter(input-counter) ".";
          position: absolute;
          top: -6px;
          left: 0;
          width: 24px;
          height: 22px;
          @include textStyle($sans-bold, 20px, $gray--dark, bold);
        }
      }
    }

    .ui.form & .nudge__suggested input {
      border-color: $notify-warn-highlight;
      border-width: 1px;
    }

    .ui.form & .input__error input {
      border-color: $notify-warn-highlight;
      border-width: 1px;
    }
  }

  .ui.buttons &__draftbtn.button {
    border-left: 2px solid;
  }

  .ui.buttons &__clonebtn.button {
    margin-right: 20px;

    i.icon.clone.outline:before {
      content: "";
      background: url(../../assets/images/clone-icon.svg) no-repeat !important;
      width: 20px;
      height: 20px;
      position: absolute;
      left: 7px;
      top: 5px;
    }
  }

  &__warningwrap--hide {
    display: none;
  }

  &__customCls {
    margin-top: 27px;
  }
}