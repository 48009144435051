@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.help-modal {
  padding: 0;
  border: 0;
  background: transparent;

  color: $white;
  font-family: $sans-reg;
  width: 58%;
  
  &__header,
  &__footer {
    background-color: $gray--darkest;
    .btnnext,
    .btnprev {
      right: 20px;
      position: absolute;
      bottom: 10px;
      @include buttonFooterCTA;
      padding: 8px 20px;
    }
  }

  &__footer {
    padding: 34px 0;
  }

  &__header {
    text-align: left;
    padding: 18px;
    .heading {
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1.4px;
      line-height: 24px;
      text-transform: uppercase;
      display: inline;
    }
    .btn-closemodal {
      display: inline;
      position: absolute;
      right: 20px;
      .ui.image {
        display: inline;

        cursor: pointer;
      }
    }
  }
  &__body {
    padding: 20px 30px;
    text-align: center;
    background: $black;
    .heading {
      font-size: 18px;
      padding: 0px;
      letter-spacing: 0;
      line-height: 38px;
      text-align: left;
    }
    .carousel-howto {
      .slide-wrapper {
        padding: 10px 50px;
      }

      .slick-dots {
        li {
          margin: 0;
          button:before {
            color: $white;
            opacity: 1;
          }
          &.slick-active button:before {
            color: $bcg-green--light-blue-green;
            font-size: 12px;
          }
        }
        bottom: -65px;
        left: 0;
        text-align: left;
      }
    }
    .announcement-text {
      box-sizing: border-box;
      border: 1px solid $bcg-green--light-blue-green;
      border-radius: 4px;
      background-color: rgba(63, 173, 147, 0.2);
      font-size: 14px;
      padding: 10px 20px;
      img {
        margin-right: 20px;
        display: inline;
      }

      .heading {
        font-size: 16px;
        padding: 0;
        line-height: normal;
        display: inline-block;
      }
    }
    .step-text {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
      margin-bottom: 10px;
      a {
        color: $bcg-green--light-blue-green;
        text-decoration: underline;
      }
      img {
        text-align: center;
        margin: 0 auto;
      }
    }
    .slide2Img {
      margin-bottom: 10px;
    }
  }
}
