// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.allocationlisting {
    margin: 20px 10px;
    padding: 10px 8px;
    background: $gray--filler;
    border-radius: 10px;
    &__topheading {
        @include textStyle($sans-bold, 14px, $black, normal, 24px);
        padding: 5px 0;
        &--extended {
            width: 92% !important;
        }
        &--contracted {
            width: 8% !important;
        }
        &--pointer {
            cursor: pointer;
        }
    }
    &__content {
        &__subheading {
            @include textStyle($sans-bold, 12px, $black, normal, 24px);
            background-color: $gray--lighttwo;
            padding: 4px 0;
        }
        &--child {
            @include textStyle($sans-reg, 12px, $gray--darkest, normal, 24px);
        }
        &--head {
            @include textStyle($sans-bold, 12px, $black, normal, 24px);
        }
    }
    &__leftcontent {
        text-align: left;
        padding: 2px 10px;
        width: 60%;
        display: inline-block;
    }
    &__rightcontent {
        text-align: right;
        padding: 2px 10px 2px 0;
        width: 40%;
        display: inline-block;
        vertical-align: top;
        .chevron {
            text-align: right;
            cursor: pointer;
        }
    }
    &__separator {
        border-bottom: 1px solid rgba(151, 151, 151, 0.2);
        display: block;
        margin: 8px 0;
    }
    &__nocontent {
        @include textStyle($sans-reg, 12px, $gray--darker, normal, 24px);
        padding-left: 10px;
    }
}
