// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.imageSelector{    
    max-width: 260px;
    &.imageSelected {      
      border-radius: 8px;
      box-shadow: 0 0 4px 0 $gray--dark;
      &:hover {
        box-shadow: 3px 2px 8px 1px $gray--dark;
        transition: box-shadow .3s ease-in-out;
      }
    }
    &.imageNotSelected {
        height: 310px;  
        border-style: dashed;
        border-color: $gray--dark;
        border-width: 1px;
    }
    &.customhighlight {
        border-color: $notify-warn-highlight;
    }
    &__tile {
      position: relative;
      max-height: 180px;
      overflow: hidden;
      &--title {
        @include textStyle($serif-bold, 20px, $white, normal, 32px);
        text-shadow: 1px 1px 0 $gray--darker;
        z-index: 2;
        position: absolute;
        top: 20px;
        padding: 0 15px;
        height: 70px;
        overflow: hidden;
        word-break: break-word;
      }
      &--button.ui.button {
        position: absolute;
        z-index: 2;
        bottom: 15px;
        left:85px;
        margin-top: 0;
      }
      &--image.ui.image {
        margin: 0 auto;
        z-index: 1;
        border-radius: 8px 8px 0 0;        
      }
    }
    &__tileText {
      min-height: 140px;
      &--desc {
        @include textStyle($sans-reg, 12px, $gray--darkest, normal, 20px);
        padding: 10px 15px;
        height: 50px;
        overflow: hidden;
      }
      &--date {
        padding-left:20px;
        margin-top: 35px;
        @include textStyle($sans-reg, 12px, $gray--brownish, normal, 20px);
      }
      &--files {
        padding-left:20px;        
        @include textStyle($sans-reg, 12px, $gray--brownish, normal, 20px);
      }
    }
    &__content {
      background-color: $gray--light;
      margin: 16px;    
      height: 277px;
      text-align: center;
      padding: 30px;
    }
    &--text {
      font-size: 14px;
      line-height: 20px;
      color: $gray--darkest;
      margin-bottom: 35px;
      display: block;
    }
    &--image.ui.image {
      margin: 0 auto;
      z-index: 1;
      border-radius: 8px 8px 0 0;
    }
    &--button.ui.button {
      @include buttonFooterCTA;
      margin-top: 55px;
      padding: 8px 15px;
      font-weight: normal;
    }
}