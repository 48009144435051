@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

@-webkit-keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }
 
 @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
 }
 

.iconlisting{
    &__tooltip{
        padding: 4px;
        font-size: 10px;
        line-height: 12px;
       
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;

    }
    &__text{
        font-family: $sans-bold;
        font-weight: bold;
        font-size: 12px;
        margin-right: 13px;
    }
    &__text-top{
        display: block;
        margin-right: 0;
    }
    &__imageslist{
        padding-left: 5px;
    }
    &__image{
        display: inline-block !important;
        height: 32px;
        width: 32px;
        border-radius: 20px;
        margin-left: -5px;
        &__1{
            z-index: 5;
        }
        &__2{
            z-index: 4;
        }
        &__3{
            z-index: 3;
        }
        &__4{
            z-index: 2;
        }
    }
    &__staticThumbnail{
        height: 32px;
        margin-left: -5px;
        z-index: 1;
        border-radius: 20px;
        background: $gray--darkest;
        display: inline-block;
        color: $white;
        padding-top: 6px;
        text-align: center;
        box-sizing: border-box;
        width: 32px;
        font-size: 12px;
    }
    &__faImage::before{
      font-family: 'Icons', sans-serif;
      color: #000;
      content: "\f007";
    }
}