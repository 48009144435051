@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.cardslayout{
&__wrapper{
&.ui.four.cards
{
margin-left: 0px;
}
}
}