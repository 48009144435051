@import "src/global-styles/colors";
@import "src/mixins/mixins";


.my-activities {
  position: relative;

  &__content {
    padding: 20px 0 0;
    @include getLayoutInnerPadding();
  }

  &__filters {
    padding-bottom: 22px;


    &--title {
      position: relative;
      background-color: $black;
      color: $white;
      margin-bottom: 10px;
      font-size: 14px;
      font-family: $sans-reg;
      padding: 10px 10px 10px 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2px;
      width: calc(100vw - 75px);
      max-width: 1567px;

      .icon-left {
        position: absolute;
        left: 15px;
        top: 4px;
        padding: 10px;
        padding-left: 115px;

      }

      .title-text {
        padding-left: 120px;
      }

      .icon-right {
        padding-right: 10px;
        margin-left: 102px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}