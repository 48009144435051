@import "src/global-styles/typography";
@import "src/global-styles/colors";

.location{
    &__name, &__icon{
        display: inline-block;
        line-height: 26px;
        vertical-align: middle;
        font-size: 16px;
        font-family: $sans-reg;
        color: $gray--darkest;
    }
    &__icon{
        margin-right: 5px;
    }
}