// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.action-button{
  background: $gray--darkest;
  border-radius: 5px;
  padding: 13px;
  cursor: pointer;
  img.ui.image{
    height: 18px;
    width: 18px;
    max-width: none;
  }
  margin: 10px;
}