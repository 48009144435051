@import "src/global-styles/colors";
@import "src/global-styles/typography";

.kpdownload {
    text-align: center;
    background: white;
    padding: 55px 20% 127px;

    &__image.ui.image {
        display: inline-block;
    }

    h2 {
        color: $gray--darkest;
        font-family: $sans-bold;
        font-size: 34px;
        line-height: 50px;
    }
}