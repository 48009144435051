// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.imagesGrid {
    position: relative;    
    padding: 20px 90px 0 35px;
    height: 100%;
    &__images {
      height: 72vh;
      overflow-y: auto;
      overflow-x: hidden;      
      margin-bottom: 20px;
      .picker { 
        border: 0;
        &__image{
          height: 100%;
        }
      }
    }
    &__header {
      position: relative;
      &--title{
        @include textStyle($serif-bold, 24px, $gray--darkest, normal, 38px);
      }
      &--subtitle{
        @include textStyle($sans-reg, 14px, $gray--darker, normal, 24px);
        margin-bottom: 20px;
      }
      &--close.ui.image {                
        position: absolute;        
        right: -50px;
        top: 0;
        cursor: pointer;
      }
    }
    &__footer {
      background-color: $gray--darkest;
      align-items: center;
      display: flex;
      flex-flow: row nowrap;
      height: 60px;
      margin: 0;
      padding: 5px 20px;
      border-bottom: 2px solid #707070;
      justify-content: flex-end;      
      position: absolute;
      bottom: 0;
      right: 90px;
      left: 50px;      
      &--button.ui.button {
        @include buttonFooterCTA;
        margin-top: 0 !important;
        padding: 8px 15px;
        font-weight: normal;        
      }
    }
  }