.facecardgeneratorv1 {
    &.main {
        margin-top: 0;
    }

    .layout__fix {
        width: 1167px;
        margin: 0 auto;
        padding: 0;
    }

    .fullpageloader__wrap {
        position: absolute;
        z-index: 3;
        top: 0;
    }

    &__resultsection {
        position: relative;
        min-height: 300px;
        margin-top: 60px;
        margin-bottom: 60px;
    }
}