@import "src/global-styles/colors";
@import "src/global-styles/typography";

.relatedCaseTile {
    position: relative;
    &__date {
        margin-bottom: 10px;
        &--formatted {
            display: block;
            height: 16.8px;
        }
    }
    &__horizontalLine {
        margin: 14px 0;
        border-bottom: 1px solid $gray--light;
    }
    &__milestone {
        list-style-type: none;
        width: 100%;
        color: $gray--darker;
        font-size: 12px;
        position: relative;
        text-align: left;
        padding-left: 5px;
        margin-bottom: 15px;
        .icon {
            position: relative;
            padding-left: 30px;
            &:before {
                content: " ";
                line-height: 18px;
                border-radius: 50%;
                width: 18px;
                height: 18px;
                border: 1px solid $gray--darkest;
                position: absolute;
                z-index: 1;
                margin-left: -4px;
                margin-top: -4px;
                background-color: $white;
            }
            &:after {
                content: " ";
                line-height: 10px;
                border-radius: 50%;
                width: 10px;
                height: 10px;
                border: 1px solid $gray--darkest;
                display: block;
                background-color: $gray--darkest;
                z-index: 2;
                position: absolute;
            }
        }
        &:after {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: $gray--darkest;
            top: 5px;
            left: 10px;
        }
    }
    &__content {
        background-color: $white;
        border: 1px solid $gray--light;
        min-height: 190px;
        &:hover {
            border-color: $gray--filler;
            box-shadow: 1px 0px 5px $gray--filler;
            color: $gray--darkest;
        }
        cursor: pointer;
        border-radius: 2px;
        padding: 10px 10px 20px 10px;
        margin: 30px 4px 20px;
        .title {
            font-weight: bold;
            margin-bottom: 6px;
            line-height: 16px;
            color: $gray--eclipse;
            height: 32px;
        }
        .desc {
            line-height: 20px;
            height: 60px;
        }
    }
}
