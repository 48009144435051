@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.csHeader {
  padding-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .consumptionShare {
    margin-top: 0;

    .infopopup__trigger {
      padding: 0;
    }
  }


  .pill {
    padding: 0 16px;
  }

  &__header-row {
    padding-top: 25px;
  }

  &__images {
    margin-top: 0px;
    display: flex;
  }

  &__divider {
    margin: 15px 15px;
    box-sizing: border-box;
    height: 18px;
    border-left: 1px solid $gray--dark;

    &.ui.divider {
      width: 96%;
      margin-left: 2%;
      box-sizing: border-box;
      height: 1px;
      border: 1px solid $gray--dark;
    }
  }

  &__header {
    padding: 0px;
    color: $gray--darkest;
    font-family: $serif-bold;
    font-size: 40px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 50px;
    margin-bottom: 19px;

    .text-right {
      text-align: right;
    }
  }

  &__tooltipInternal {
    .tooltipwrap__popup.content {
      max-width: 500px;
      white-space: normal;
      word-break: normal;
      padding: 15px 20px;
    }
  }

  &__internalofficeContent {
    text-align: left;
    font-size: 12px;
    line-height: 18px;
  }

  &__countInternalOffice {
    cursor: pointer;
  }

  &__downloads {
    padding-bottom: 4px;
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 24px;
  }

  &__thumbs {
    margin-left: 10px;
  }

  &__divider--thumbs {
    display: none;
  }

  &__count+&__divider--thumbs {
    display: inline;
  }

  &__share-icon {
    margin-right: 20px;
  }

  &__audit {
    color: #707070;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
  }

  &__count {
    margin-left: 10px;
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 20px;
    text-align: center;
  }

  &__starimg {
    &.icon {
      color: $bcg-green--light-blue-green;
    }
  }
}