@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.draggable {
  display: unset;

  &__item {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    margin: 5px 0;
    padding: 17px;
    min-height: 75px;
    border-radius: 1px;
    border: solid 1px $gray--light;
    background-color: $white;

    &:hover {
      box-shadow: 1px 0px 5px $gray--dark;
      cursor: pointer;
    }

    //Custom css to handle Drag Drop Feature Start //
    &--custom {
      display: inline-block;
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &>.draggable__handler {
        position: absolute;
        top: 13px;
        left: 12px;

        &:after,
        &:before {
          content: "\2807";
          font-size: 28px;
        }
      }
    }

    //Custom css to handle Drag Drop Feature End //
  }

  &__handler {
    position: relative;
    top: 4px;
    margin-right: 5px;
    cursor: move;
    /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &:after,
    &:before {
      content: "\2807";
      color: $gray--dark;
      margin: -7px;
      font-size: 30px;
    }
  }
}