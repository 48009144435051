@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.listBtnView {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &__active {
        padding: 4px;
        border: 1px dotted $green-light;
        border-radius: 4px;
        margin-left: 10px;

        &>.viewIconTitle {
            @include textStyle($sans-reg, 9px, $green-light, bold, 20px);
            height: 15px;
            margin-top: 3px;
        }

        &:hover {
            background-color: $black-lighter;
        }
    }

    &__nonActive {
        padding: 4px;
        border: none;
        margin-left: 10px;

        &>.viewIconTitle {
            @include textStyle($sans-reg, 9px, $gray--darkest, bold, 20px);
            height: 15px;
            margin-top: 3px;
        }

        &:hover {
            background-color: $black-lighter;
            border-radius: 4px;
        }
    }

}