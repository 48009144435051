// Import
@import "src/global-styles/colors";
@import "src/global-styles/typography";

@mixin verticalLine {
  content: "";
  border-right: 1px solid $gray;
  height: 100%;
}
.rich {
  display: flex;
  flex-flow: column nowrap;
  height: 360px;
  &__toolbar {
    border-radius: 0px 0px 5px 5px;
    order: 2;
    padding: 9px 10px;
    margin: 0;
    border: 1px solid $gray--dark;
    .rdw-inline-wrapper,
    .rdw-list-wrapper {
      margin-bottom: 0px;
    }
    .rdw-inline-wrapper {
      margin-right: 8px;
    }
    .rdw-option-wrapper {
      border-color: $gray--whitish;
      margin: 0;
      padding: 2px 1px;
    }
    .rdw-dropdown-wrapper {
      height: 20px;
      border: unset;
    }
  }
  &__editor {
    // update rich editor text color
    color: $blackthree;
    background-color: $white;
    border: 1px solid $gray--dark;
    border-bottom: unset;
    border-radius: 5px 5px 0px 0px;
    order: 1;
    padding: 10px;
    height: calc(100% - 46px);
    overflow: unset;
    overflow-y: auto;
    font-family: $sans-reg;
    p, ul, div, pre, h1, h2, h3, h4, h5, h6, blockquote {
      margin: 0;
    }
  }
}
