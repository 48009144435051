@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.knowledge-objects {
  &__searchWrap {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }
  &--empty {
    color: $gray--dark;
    font-family: $sans-reg;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    text-align: center;
    opacity: 0.6;
    max-width: 736px;
    margin: 0 auto;
  }
  .add-ki {
    max-width: 786px;
    width: 100%;
    text-align: left;

    @media (min-width: map-get($grid-breakpoints, xl)) {
      max-width: 100%;
    }

    &__group.fields {
      display: block;
      margin-bottom: 0 !important;

      &__field {
        width: 50%;
        min-width: 700px;
      }
      .add-ki__searchedField.field {
        border: 1px solid $gray--dark;
        border-radius: 4px;
        box-shadow: 1px 1px 20px $gray--light;
        width: 890px;
        padding: 5px;        
        overflow-y: auto;
        max-height: 368px;
        position: absolute;
        z-index: 4;
        background: white;
        > .field {
          margin: 10px 0;
          height: 44px;
          .attachment__title,
          .attachment__sub-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          cursor: pointer;
          &.disabled {
            opacity: 0.5;
            cursor: auto;
          }
          &:not(.disabled):hover {
            background-color: $gray--darkest;
            .attachment__title,
            .attachment__sub-title {
              color: $white;
            }
          }
        }
      }
    }

    &__label {
      font-size: 14px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      color: $black;
      margin-bottom: 5px;
      font-family: $sans-reg;
    }
    &__labelwrap{
      position: relative;
      display: inline-block;
      vertical-align: top;
    }
    &__wrapper {
      position: relative;
      max-width: 890px;
      .radiations {
        position: absolute;
        top: -17px;
        right: -49px;
        display: inline-block;
        color: $regulargray;
        font-size: 16px;
      }
    }
    &__input {
      margin-bottom: 20px;
      margin-right: 0;
      .ui.input {
        width: 890px !important;
        max-width: 890px;
        input {
          padding: 15px;
        }
      }
      .searchicon {
        position: absolute;
        top: 0px;
        bottom: 0px;
        margin: auto;
        right: 15px;
        cursor: pointer;
      }
    }
    &__errormsg {
      @include errorMsg;
    }
    &__recentContributions {
      color: $gray--dark;
      font-family: $sans-bold;
      font-size: 14px;
      line-height: 20px;
      text-transform: uppercase;
      padding: 10px 0 0 18px;
    }
  }
  &--caseItems {
    margin-left: -6px;
    &.container-fluid {
      padding: 0px;
    }
    .ui.form .inline.fields {
      .add-ki__input {
        &.field {
          padding: 0;
        }
        .ui.input {
          width: 100% !important;
        }  
      }
    }
    .add-ki__group.fields {
      .add-ki__searchedField.field {
        width: 100%;
      }
      .add-ki__field.field {
        padding-right: 0;
      }
    }
    .add-ki__label {
      color: $gray--darkest;
    }
  }
}
.ui.image {
  &.add-ki__image--empty-list {
    margin: 30px auto 0;
  }
}
