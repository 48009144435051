// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.kpstep3__wrap {
  .ui.form & .inline-label {
    display: inline-block;
  }

  .additionalUrls {
    .add-supportinglinks {
      padding: 20px 10px;

      &__multiTiles {
        margin: 0 20px;
      }
    }
  }

  .add-supportinglinks {
    @include borderedField;
    padding: 0;

    .fields .field.field>label.add-supportinglinks__label:before {
      content: none;
    }
  }

  .ui.form & .fields {
    & .add-supportinglinks__wrapper {
      margin-bottom: 0px;

      &.inline.fields {
        >.field {
          width: calc(50% - 20px);
        }
      }
    }
  }

  .ui.form & .fields {
    .field.countriesForPage .ui.input {
      width: 70%
    }

    .field.officeLocation .ui.input {
      width: 30%
    }

    .field.advancedDropdown__searchbox .ui.input input {
      padding: 10px 10px 10px 40px;
      margin: 10px 20px;
    }

    .ui.dropdown:not(.button)>.default.text {
      color: $gray--brownish;
    }

    .radio-wrapper {
      margin-top: 5px;

      .field {
        display: inline-block;

        &:first-of-type {
          margin-right: 20px;
        }
      }
    }

    &.grouped {
      &.fields {
        padding-left: 0;
      }

      .grouped-items {
        border: 1px solid $checkboxgray;
        border-radius: 7px;
        padding: 10px 20px;

        >.field {
          margin: 10px 0 30px 0;

          .custodianWrapper {
            margin-top: 30px;
          }

          div.customhighlight {
            border: 1px solid $notify-warn-highlight;
            border-radius: 4px;
            padding: 5px 10px;
          }

          &:last-child {
            margin-bottom: 10px;
          }

          .field {
            margin: 0;
          }
        }
      }

      .field label {
        height: 24px;
        @include textStyle($sans-reg, 14px, $gray--darkest, bold);

        &:before {
          content: none;
        }
      }

      >label {
        height: 24px;
        @include textStyle($sans-reg, 14px, $gray--darkest, bold);
        margin-left: 20px;

        &:before {
          counter-increment: input-counter;
          content: counter(input-counter) ".";
          position: absolute;
          top: -5px;
          left: 0;
          width: 24px;
          height: 22px;
          @include textStyle($sans-bold, 20px, $gray--dark, bold);
        }
      }
    }

    .field.field-regions>label {
      &:before {
        top: 15px;
      }
    }
  }

  &--underline {
    text-decoration: underline;
  }

  .officeLocation {
    max-width: 265px;

    &.customhighlight {
      border-color: $notify-warn-highlight;
    }
  }

  .knowledgeDropdown {
    .customize__dropdownlist {
      max-height: 240px;
    }
  }

  &__makeCheckbox {
    .ui.radio.checkbox label {
      &:before {
        border-radius: 3px;
      }

      &:after {
        content: '\e800' !important;
        height: 15px;
        width: 15px;
        transform: none;
        background-color: #3FAD93 !important;
        color: #fff !important;
        font-size: 10px;
        border-radius: 3px;
      }
    }

    &--error {
      .ui.radio.checkbox label {
        &:before {
          border-color: $notify-warn-highlight;

          &:checked {
            border-color: #d4d4d5;
          }
        }
      }
    }
  }
}