@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.drawfromright {
    &.ReactModal {
        &__Overlay {
            opacity: 0;
            transform: translateX(100px);
            transition: all 200ms ease-in-out;

            &--after-open {
                opacity: 1;
                transform: translateX(0px);
            }

            &--before-close {
                opacity: 0;
                transform: translateX(100px);
            }
        }
    }
}

.casehistorymodal {
    height: 100%;
    width: 90%;
    left: 55%;
    padding: 0;
    font-family: $sans-reg;
    border: none;
    border-radius: 0;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.32);
    background-color: $gray--light;

    &__header {
        background-color: $gray--darkest;
        padding: 20px;
        display: flex;
    }

    &__headertitle {
        margin-left: 15px;
        color: $white;
        font-size: 16px;
        font-family: $sans-bold;
        letter-spacing: 1.07px;
        line-height: 35px;
    }

    &__headerclose {
        background: none;
        color: $white;
        font-size: 24px;
        line-height: 20px;
        margin-left: auto;
        height: 20px;
        width: 20px;
        outline: none;
        padding: 0;
        border: none;
    }

    &__headericon.ui.image {
        padding: 8px;
        border-radius: 4px;
        width: 35px;
        background: $black;
        height: 35px;
    }

    &__columns {
        display: flex;
        padding: 16px 20px;
        justify-content: space-between;
    }




    &__content {
        padding: 5px 20px;
        background-color: $gray--light;
    }
}