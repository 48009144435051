@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.customize {
  &__msdd {
    position: relative;
  }
  &__msddtxt {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin:2px 0;
    background: $white;
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    color: $gray--darkest;
    cursor: pointer;
    font-family: $sans-reg;
    .accordian-icon {
      float: right;
      margin-right: 13px;
    }

    .highlighted {
      color: $notify-warn-highlight;
      font-weight: bold;
    }
    
    &.expanded, &[data-accordian='expanded'] {
      .accordian-icon {
        transform: translateX(-5px) rotate(180deg);
      }
    }
  }  
  &__msddlist {
    max-height: 270px;
    overflow: auto;    
    width: 100%;
    z-index: 1;
    > li {      
      .option{
        padding-left: 20px;
      }
    }
    > li  ul > li {
      .option {
        padding-left: 40px;
      }
    }
    > li  ul > li  ul > li {
      .option {
        padding-left: 60px;
      }
    }
    > li  ul > li  ul > li ul > li {
      .option {
        padding-left: 80px;
      }
    }
    > li  ul > li  ul > li ul > li ul > li {
      .option {
        padding-left: 100px;
      }
    }
    > li  ul > li  ul > li ul > li ul > li ul > li {
      .option {
        padding-left: 120px;
      }
    }
  }

  &__msddsublist {   
    display: none;
    
    &.expanded {
      display: block;
    }
  }

  
  &__msddstyle {
    cursor: pointer;
    & .option {
      padding: 5px;
      display: inline-block;
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      color: $gray--darkest;
      font-family: $sans-reg;
      .customcheckbox{
        &:before{
          @include hollowCheckbox;        
        }
      }
      &:hover{        
        .customcheckbox{
          &:before{
            border-color: $white;          
          }
        }        
        background: $gray--darkest;
        color: $white;        
      }
 
      &.selected, &.checked  {
        .customcheckbox {
          &:before {
            @include checkedCheckbox;
          }
        }
      }
      &.partial {
        .customcheckbox{
          &:before {
            @include checkedCheckbox;
            content: "\2212";
            padding-left: 5px;
            padding-right: 5px;
          }
      }
      }
    }
    
    
  }
  &__msdd--single {
    text-transform: none;
    &.itemhidden {
      display: none;
    }
  }
  
  &__msdd--hierarchical {
     &.child-hidden:not(.itemshown) {
      display: none;
    }
    &.child-shown {
      display: block
    }
  }
}

