// Import
@import "src/global-styles/colors";
@import "src/global-styles/typography";

.workflow {
  border-radius: 13px 13px 0 0;
  box-shadow: 0 7px 42px 0 rgba(0, 0, 0, 0.19);

  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin: 0;
  padding: 20px 25px 20px 25px;
  background: $white;

  &__items {
    flex: 1 1 auto;

    &:last-child {
      flex: none;

      .workflow__label {
        padding: 0;
        text-align: right;
      }

      .workflow__heading {
        position: relative;
        right: 42px;
      }

      .workflow__input {
        top: -35px;
        position: relative;
        right: -25px;
      }
    }
  }

  &__label {
    display: block;
    position: relative;
    padding-left: 45px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0;

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    &--active {
      input~.workflow__input--check-mark {
        background-color: $bcg-green--light-blue-green;
      }
    }

    input:checked~.workflow__input--check-mark:after {
      display: block;
    }
  }

  &__heading {
    &--heading {
      color: $black;
      font-family: $sans-bold;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 16px;
    }

    &--sub-heading {
      color: $gray--dark;
      font-family: $sans-reg;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 20px;
    }
  }

  &__input {
    &--check-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 34px;
      width: 34px;
      border-radius: 5px;
      background-color: $gray--filler;

      &--done {
        background-color: $gray--dark;
      }

      &--done::after {
        content: "";
        position: absolute;
        display: none;
        left: 13px;
        top: 6px;
        width: 9px;
        height: 16px;
        border: solid $white;
        background-color: $gray--dark;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  &__sanitization {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 20px;
      left: 0;
      height: 70px;
      background: $white;
      width: 100%;
      z-index: 0;
    }
  }

  &-border {
    border-bottom: 4px solid $bcg-green--light-blue-green;

    &__sanitization {
      position: relative;
      z-index: 1;
    }
  }
}

.sanitization-banner {
  height: 40px;
  width: 100%;
  background: #1991EB;
  color: #000;
  display: flex;
  align-items: center;
  position: relative;

  &>img {
    margin: 0 7px 0 23px;
  }

  &>span {
    color: #FFFFFF;
    font-family: $sans-light;
    font-size: 14px;
  }
}

#workflow-TPKB {
  .workflow__items:last-child .workflow__input {
    right: -55px;
  }
}

#workflow-KP {
  .workflow__items:last-child .workflow__input {
    right: -148px;
  }
}