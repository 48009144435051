@import "src/global-styles/colors";
@import "src/global-styles/typography";

.kea {
  &__btn--disabled {
    cursor: initial;
  }

  &__btn {
    background: none;
    border: 0;
    text-align: left;

    &:focus {
      outline: none;
    }

  }

  &__btn-icon {
    width: 25px;
  }

  &__list-item {
    display: flex;
    align-items: center;
    padding: 6px 0 6px 12px;
    font-size: 14px;
    line-height: 24px;
  }

  &__list-draft {
    display: inline-block;
    align-items: center;
    padding: 6px 0 6px 6px;
    font-size: 14px;
    line-height: 24px;
  }

  li+&__list-separated {
    border-top: 2px solid $gray--whitish;
  }

  &__list-item-icon {
    margin-left: auto;
  }

  &__contributeKnowledge {
    .tooltipwrap {
      margin-left: 0;
      display: block;

      .kea__btn {
        background: #f2f2f2;
      }

      .kea__list-item {
        // opacity: 0.5;
        color: #b7b7b7;

      }

      .tooltipwrap__popup p {
        color: #fff;
      }
    }
  }
}

.stopBubble {
  &__btn--disabled {
    background-color: $gray--light;
    opacity: 1;
    color: $gray--dark;

    &>.kea__btn--disabled>.kea__list-item {
      color: $gray--dark;
    }
  }
}