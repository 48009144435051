@import "src/global-styles/colors";
@import "src/mixins/_mixins";

.nudgepopup {
  &__wrap {
    position: relative;
  }

  &__suggestions {
    position: absolute;
    top: 0;
    box-shadow: 0 2px 12px 0 rgba(255, 152, 0, 0.77);
    // box-shadow: 1px 1px 1px 1px $notify-warn-highlight;
    border-radius: 8px;
    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
    transition: opacity 0.5s, visibility 0.5s;
    opacity: 1;
    width: 57%;
    left: calc(100% + 20px);
    z-index: 1;
    background: $white;
    overflow: hidden;

    &--passed {
      // border: 1px solid $bcg-green;
      box-shadow: 0 2px 12px 0 rgba(23, 123, 87, 0.77);
      // opacity: 0;
    }

    &--hide {
      opacity: 0;
      visibility: hidden;
    }

    .labelWithText {
      position: absolute;
      right: 10px;
      top: 20px;
      margin-right: 21px;

      &__additional {
        text-align: right;
        width: 56px;
        display: inline-block;
        vertical-align: sub;

      }
    }

    &:before {
      content: "";
      position: absolute;
      background: url("../../../assets/images/nudge3.svg") no-repeat;
      width: 56px;
      height: 93px;
      left: 0;
      bottom: 10px;
      z-index: -1;
    }

    &:after {
      content: "";
      position: absolute;
      background: url("../../../assets/images/nudge2.svg") no-repeat;
      width: 71px;
      height: 105px;
      right: 0;
      bottom: 30px;
      z-index: -1;
    }

    &__highZIndex {
      z-index: 99;
    }
  }

  &__heading {
    font-size: 14px;
    line-height: 24px;
    font-family: $sans-bold;
    color: $gray--darkest;
    margin: 0;
    padding: 20px 13px 10px;

    &:before {
      content: "";
      position: absolute;
      background: url("../../../assets/images/nudge1.svg") no-repeat;
      width: 72px;
      height: 95px;
      right: -5px;
      top: -7px;
      z-index: -1;
    }

    span {
      display: inline-block;
      vertical-align: top;
      border: 2px solid $notify-warn-highlight;
      border-radius: 14px;
      padding: 0px 12px;
    }

    .nudgepopup__suggestions--passed & span {
      border-color: $bcg-green;
    }
  }

  &__suggestions--passed {}

  &__crossicon.ui.image {
    position: absolute;
    right: 16px;
    top: 29px;
    width: 8px;
    cursor: pointer;
  }

  &__suggestionslist {
    padding: 0 28px 0 41px;

    li {
      font-size: 12px;
      font-family: $sans-bold;
      line-height: 18px;
      color: $gray--darkest;
      margin-bottom: 22px;

      span {
        display: inline-block;
        width: calc(100% - 20px);
        vertical-align: top;
        margin-left: 6px;
      }
    }
  }

  &__list {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &>img {
      width: 14px;
      height: 17px;
      object-fit: scale-down;
    }
  }

  &__orangeIcon.ui.image {
    margin-right: 9px;
    width: 18px;
    display: inline-block;
  }

  &__feedback {
    padding: 10px 16px;
    text-align: right;
    border-top: 1px solid $gray--dark;
  }

  &__feedback-heading {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 22px;
  }

  &__feedback-thumbs {
    margin-left: 10px;
  }

  &__nudgeicon {
    &.ui.image {
      width: 12px;
      opacity: 1;
      transition: opacity 0.5s, visibility 0.5s;
      cursor: pointer;
    }

    &--hide {
      &.ui.image {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__righttop {
    position: absolute;
    right: 2px;
    top: -28px;
    align-items: flex-end;
    display: flex;
  }

  &__suggestionpassed {
    font-family: $sans-reg;
  }

  &__status {
    img.ui.image {
      float: left;
      height: 25px;
      margin-right: 10px;
    }

    span {
      vertical-align: middle;
      color: $gray--darkest;
    }
  }
}

.nudge__suggested {
  .txbase__wrapinput {
    border-color: $notify-warn-highlight;
  }

  //Below css to show highlight on internal office field in case of nudge in yellow color
  &.office__wrapinput {
    .slctrbase__wrapinput {
      border-color: $notify-warn-highlight;
    }
  }
}