@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.casebar {
	background-color: $gray--bar;
	padding: 14px 10px;
	&__caseList {
		max-width: 20%;
		display: inline-block;
		vertical-align: middle;
		margin-right: 30px;
	}
	&__heading {
		display: block;
		@include textStyle($sans-reg, 14px, $gray--darkest, normal, 24px);
	}
	&__data {
		@include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px);
		color: $gray--darkest;
		&--active {
			color: $activegreen;
		}
	}
	&__datalink {
		@include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px);
		color: $gray--darkest;
		&:hover{
			color: $gray--darkest;	
			text-decoration: underline;
		}
	}
	&__link {
		color: $white;
		&:hover {
			color: $white;
			text-decoration: underline;
		}
	}
}
