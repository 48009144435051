@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.paRecommended {
  display: inline-block;

  &--pabutton {
    &__icon {
      color: $bcg-green;
      margin-right: 5px;
    }

    &__text {
      color: $gray--darkest;
    }

    display: inline-block;
    padding: 5px 12px;
    cursor: pointer;
    margin-left: 15px;
    border-radius: 16px;
    background-color: $white;
    height: 32px;

    img.ui.image {
      display: inline-block;
      margin-right: 8px;
      vertical-align: top;
      margin-top: 5px;
    }

    &.disabled {
      @include disabledLink;

      .paRecommended--pabutton__icon {
        color: $gray--dark;
        margin-right: 5px;
      }

      .paRecommended--pabutton__text {
        color: $gray--dark;
      }
    }
  }

  &--cancel {
    float: left;
  }

  &--save {
    float: right;
  }
}

.popup {
  padding: 23px 33px 15px 33px;

  &--pill {
    @include textPill();
    padding: 3px 16px;
    margin-right: 8px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 8px;
  }

  &--blackPill {
    @include darkTextPill();
    margin-right: 8px;
    padding: 3px 16px;
    display: inline-block;
    cursor: pointer;
    margin-bottom: 8px;
  }

  &--category {
    color: $gray--darkest;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 12px 0 5px 0;
  }
}