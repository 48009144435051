// Import
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.ui.dimmer{
  background-color: rgba(0, 0, 0, 0.6);
}
.items-modal.ui.modal{
  position: static;
}
.ui.button.footer-workflow__button--next.footer-workflow__btn{
  pointer-events: all !important;
}
.items-modal{
  height: 141px;
  width: 408px!important;
  top: auto;
  left: auto;
  &__description{
    text-align: center;
    &--heading{
      margin-bottom: 5px;
      @include textStyle($sans-bold, 14px, $gray--darkest, normal, 17px);
      i {
        width: 24px;
        height: 24px;
        font-size: 24px;
        margin-right: 15px;
        vertical-align: middle;
        margin-top: 5px;
        color: $bcggreenthree
      }
    }
    &--subHeading{
      font-size: 13px;
      font-weight: normal;
      color: $regulargray;
      padding: 5px 0;
    }
    &--button.ui.button{
      margin: 5px !important;
      @include buttonFooterCTA;
    }
    &--subheading{
      margin-top: 10px;
      @include textStyle($sans-light, 12px, $gray--dark, normal, 14px)
    }
  }
  &__descriptionWrapper {
    margin-top: 20px;
  }
}

.KBStep2 {
  .tabs_container {
    background: $white;
  }
  .ui {
      &.secondary.menu,
      &.bottom.segment.tab {
        @include getLayoutInnerPadding();
      }
      &.secondary.menu {
        background: $white;
        border-radius: 13px 13px 0 0;
        margin-bottom: 0;
      }
    }
}

.assets-message {
  color: $gray--darkest;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 8px;
  text-align: right;
  font-family: $sans-reg;
}