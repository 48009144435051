@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.creationOption {
  &__modal {
    width: 40%;
    min-width: 762px;
    padding: 30px 20px;
  }
  &__section {
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    .txt {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 10px;
      color: $gray--darkest;
    }
    .txt-bold {
      font-weight: bold;
    }
  }
  &__box {
    width: 100%;
    max-width: 352px;
    padding: 15px 15px 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .image {
      padding-bottom: 13px;
    }
    &.selected {
      background-color: $gray--lightest;
      box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
      border-radius: 5px;
    }
  }
  &__modalcross {
    position: absolute;
    top: 14px;
    right: 14px;
    width: 11px;
    height: 11px;
    cursor: pointer;
  }
  &__btn {
    padding: 0 10px;
    &:after {
      content: "";
      display: block;
      clear: both;
    }
    .cancel-btn {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.8px;
      border-radius: 5px;
      background: $white;
      color: $black;
      border: 2px solid $black;
      padding: 8px 37px;
      font-family: $sans-reg;
      font-weight: bold;
      float: left;
      &:hover {
        background: $white;
        color: $black;
        box-shadow: none;
      }
    }
    .create-btn {
      @include buttonFooterCTA;
      padding: 8px 25px;
      float: right;
      margin-top: 6px;
    }
  }
}
