// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.layout {
  .main {
    background-color: $gray--lightest;
  }

  &__casepage {
    .loader {
      &__wrap {
        // margin-top: -31px;
        margin-top: 100px;
        margin-bottom: 150px;
      }
    }

    &--restricted {
      padding-top: 50px;
    }

    .casepage {
      .tabs__casepage__withdrawn {
        width: auto !important;

      }
    }

    .tabs {
      &__container.tabs__casepage {
        .ui.secondary.pointing.menu {
          padding-right: 0;

          .tabs__casepage__footerWorkflow.leftElement {
            padding-bottom: 0;
            margin-left: auto;
            padding-right: 0;
          }
        }

      }
    }
  }
}


.breadCrumb {
  .title {
    font-family: $sans-bold;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 24px;
  }
}


.ui.buttons {
  .button.ui.green.big {
    width: 139px;
    height: 45px;
  }
}

.casepage {
  // &__requiredLabel {
  //   position: absolute;
  //   right: 20px;
  //   top: 1px;
  //   @include textStyle($sans-reg, 14px, $gray--darkest, 400);
  // }
  position: relative;
  width: 100%;

  &__content {
    padding: 50px 0 0;
    @include getLayoutInnerPadding();
  }

  .ui.form & .fields {
    position: relative;
    margin-bottom: 30px;

    .disabled.field {
      opacity: 0.5;

      &>label {
        opacity: 1;
      }

      .input {
        opacity: 1;
      }

      input {
        background-color: $gray--light;
      }
    }

    .ui.icon.input>input {
      padding-right: 65px !important;
    }

    input {
      border: 1px solid $gray--dark;
      padding: 22px 12px;
      font-size: 14px;
    }

    .counter {
      position: absolute;
      right: 8px;
      top: 20px;
    }

    // .ui.dropdown{
    //   padding: 20px 10px;
    //   width: 256px;
    //   border: 1px solid $gray--dark;
    //   border-radius: 4px;
    //   background-color: $white;
    //   .icon {
    //     top: 15px;
    //   }
    // }
    // .customhighlight > div,.customhighlight > div > div, .customhighlight  input {
    //   border-color: $notify-warn-highlight !important;
    // }
    // .field{
    //   label + .tooltip__wrap { //styling to only siblings of tooltip
    //     display: inline-block;
    //     margin-left: 10px;
    //     vertical-align: top;
    //     margin-top: 4px;
    //   }
    // }
    .field>label {
      @include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px);

      &.label__disabled {
        display: inline-block;
        vertical-align: top;
        opacity: 0.5;
      }
    }
  }

  // .ui.form & .input__error input {
  //   border-color: $notify-warn-highlight;
  // }

  .tabs {
    &__casepage {
      .cpstep {
        padding: 20px 0;
      }

      &__withdrawn {
        float: right;
        width: calc(100% - 680px) !important;
        height: 60px;

        .ui.accordion {
          text-align: right !important;
        }
      }

      .ui.form & .input__error input {
        border-color: $notify-warn-highlight;
      }

      .ui.attached.bottom.segment {
        padding-left: 134px;
        padding-right: 134px;
        max-width: 1567px;
        background-color: transparent;
      }

      .ui.secondary.pointing.menu {
        margin-left: 0;
        height: auto;
        min-height: 75px;
        padding-bottom: 1px;
        border-bottom: 3px solid $gray--darker;
        position: fixed;
        background: $white;
        width: calc(100% - 60px);
        margin-top: -60px;
        z-index: 1005;
        border-top-left-radius: 13px;
        border-top-right-radius: 13px;
        padding-top: 0;
        @include getLayoutInnerPadding();

        .item {
          padding: 20px 23px 20px 23px;
          width: auto;
          display: block;
          align-self: center;

          .fields {
            .ui.checkbox {
              top: 46%;
            }
          }

          @media (max-width: 1400px) {
            padding: 20px 8px 20px 8px;
          }

          &-style {
            text-align: center;

            @media (max-width: 1400px) {
              font-size: 14px;
            }

            .ui.image {
              display: inline-block;
            }
          }
        }

        .leftElement {
          padding-top: 0px;
          margin-right: 5px;
          padding-right: 22px;

          &__count {
            background: none;
            color: $gray--darkest;
            font-size: 36px;
            padding: 0;
            line-height: 40px;
            margin-left: 14px;
            border-right: 1px solid $gray--dark;
            padding-right: 20px;
            border-radius: 0;
            font-family: $sans-reg;
            font-weight: 400;
          }
        }
      }
    }
  }
}