@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.infopopup {
  position: relative;
  height: 35px;

  &__content.ui.popup {
    z-index: 1;
    display: block;
    background-color: $white;
    border-radius: 4px;
    box-sizing: border-box;
    position: absolute;
    padding: 0;

    &:before {
      content: none;
    }
  }

  &__trigger {
    background-color: transparent;
    border: none;
    outline: none;
    min-width: 35px;
    min-height: 35px;

    &:focus,
    &:active,
    &:hover {
      background-color: $gray--light;
      border-radius: 4px;
      outline: none;
    }
  }
}