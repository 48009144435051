@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.kent {
  width: 100%;
  background-color: $white;
  font-family: $sans-reg;
  padding: 12px 16px 12px;
  margin-bottom: 8px;
  @extend %shadow--1;

  &:hover {
    @extend %shadow--2;
  }

  &__actions {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  &__row1 {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__col1 {
    width: 166px;
    margin-right: 8px;
  }

  &__col2 {
    flex: 1;
    margin-right: 100px;
  }

  &__inline {
    display: flex;
  }

  &__col3 {
    white-space: nowrap;
    text-align: right;
    margin-left: auto;
  }

  &__icontype {
    display: flex;
    align-items: center;
  }

  &__icon-wrapper {
    display: inline-block;
    background-color: $bcggreensix;
    border-radius: 50%;
    width: 37px;
    height: 37px;
  }

  &__icon {
    margin: auto;
    top: 50%;
    transform: translate(0, -50%);
    height: 24px;
    width: 24px;
  }

  &__page {
    display: inline-block;
    object-fit: fill;
    padding: 14px;
  }

  &__date {
    color: $gray--darker;
    font-size: 12px;
    line-height: 20px;
    padding-top: 5px;
    white-space: nowrap;
  }

  &__title {
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 18px;
    line-height: 24px;
    @include multilineellipsis(2);
  }

  &__description {
    padding-top: 8px;
    color: $gray--eclipse;
    font-size: 12px;
    line-height: 20px;
    word-break: break-word;
  }

  &__pill-type {
    border-radius: 11px;
    display: inline-block;
    font-family: $sans-reg;
    font-size: 12px;
    line-height: 14px;
    color: $gray--darker;
    background-color: $gray--light;
    padding: 2px 8px;
    margin-left: 8px;
  }

  &__toppill {
    margin-bottom: 9px;
    border-radius: 11px;
    display: inline-block;
    font-family: $sans-bold;
    font-size: 12px;
    line-height: 14px;
    color: $gray--darker;
    background-color: $gray--light;
    padding: 5px 12px;
  }

  &__pill-status,
  &__pill-status--draft,
  &__pill-status--published,
  &__pill-status--archived,
  &__pill-status--inqueue,
  &__pill-status--withdraw {
    border-radius: 0 0 11px 11px;
    display: inline-block;
    font-family: $sans-bold;
    font-size: 12px;
    line-height: 20px;
    color: $white;
    width: 91px;
    text-align: center;
    padding: 3px 0;
    position: absolute;
    top: -13px;
    right: 0;
  }

  &__pill-status--draft {
    background-color: $notify-warn-highlight;
  }

  &__pill-status--published {
    background-color: $notify-success-highlight;
  }

  &__pill-status--archived {
    background-color: $gray--darker;
  }

  &__pill-status--withdraw {
    background-color: $gray--darkest;
  }

  &__pill-status--inqueue {
    background-color: $notify-warn-highlight;
  }


  &__version {
    color: $gray--darker;
    font-family: $sans-reg;
    font-size: 12px;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin-right: 20px;
  }

  &__version-number {
    font-family: $sans-bold;
    letter-spacing: 0;
    line-height: 20px;
    padding-left: 8px;
  }
}