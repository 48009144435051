@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.proposalbio {
	margin-bottom: 80px;
	margin-top: 20px;
	display: flex;
	background-color: $white;
	border: 1px solid $gray--lighttfour;
	font-family: $sans-trebuchet;

	&__image_container {
		display: flex;
		justify-content: center;
		align-items: center;
		border: 3px solid $bcggreenthree;
		border-radius: 50%;
		width: 160px;
		height: 160px;
		margin: 0 auto 10px;

		&--square {
			border-radius: 0%;
		}
	}

	&__source {
		position: absolute;
		bottom: 40px;
		font-size: 10px;
	}

	&__image {
		width: 100%;
		height: 100%;
		filter: none;
		border-radius: inherit;

		&--gray {
			filter: grayscale(100%);
			width: 100%;
			height: 100%;
		}
	}

	&__name {
		font-size: 32px;
		color: $bcggreenthree;
		font-family: $sans-trebuchet;
		margin-bottom: 15px;
		font-weight: normal;
	}

	&__position,
	&__location {
		font-size: 16px;
		font-family: $sans-trebuchet;
		color: $bcggreenthree;
	}

	&__profile_section {
		padding-bottom: 80px;
		padding-top: 50px;
		padding-left: 42px;
		background-color: $gray--light;
		width: 38%;
		text-align: left;
		padding-right: 28px;
		border-right: 1px solid $gray--whitish;
		position: relative;

		&__label {
			margin-top: 27px;
			color: $blackfour;
			width: 351px;
		}

		li::marker {
			color: $black;
		}
	}

	&__details_section {
		position: relative;
		width: 62%;
		padding: 20px 50px 0 50px;

		&__label {
			margin-bottom: 20px;
			margin-top: 15px;
			width: 545px;
		}

		li::marker {
			color: $black;
		}
	}

	&__copyright {
		position: absolute;
		bottom: 35px;
		right: 4px;
		writing-mode: vertical-lr;
		transform: rotateZ(-180deg);
		font-size: 10px;
	}

	&--green {
		.proposalbio {
			&__profile_section {
				background-image: linear-gradient(#1aab67, #0c6a3e);
			}

			&__source {
				color: $white;
			}

			&__name {
				color: $white;
			}

			&__position,
			&__location {
				color: $white;
			}

			&__details_section {
				background-color: $gray--light;
			}

			&__image_container {
				border-color: $grayProposal;
			}
		}
	}
}