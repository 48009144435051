@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.add-supportinglinks {
  .ui.form &__wrapper.inline.fields {
    .field {
      padding: 10px 10px 0 0;

      .ui.input input {
        padding: 18px 10px;
        position: relative;
      }

      .ui.input {
        &.icon input {
          padding-right: 74px !important;
        }
      }

      .counter {
        top: 15px;
        position: absolute;
        right: 15px;
      }
    }

    &__label {
      @include textStyle($sans-reg, 14px, $black, normal, 24px);
      margin-bottom: 10px;
    }
  }

  &__input.field {
    &.customhighlight {
      input {
        border: 1px solid $notify-warn-highlight !important;
        border-radius: 4px;
      }
    }
  }


  &__singleTile {
    padding: 20px 10px;

    .tile {
      img.ui.image {
        margin-right: 0;
      }

      .slink__tile {
        background: $white;
        @include borderedField;
        padding: 10px;
        max-width: calc(100% - 55px);
        align-items: flex-start;

        img.ui.image {
          margin-right: 10px;
        }
      }
    }
  }

  &__button {
    .ui.button {
      background: none;
      padding: 0;

      img.ui.image {
        margin-right: 0;
      }
    }
  }

  &__error {
    min-height: 30px;
  }

  &__errormsg {
    position: absolute;
    @include errorMsg;

  }

  img.ui.image {
    display: inline;
    margin-right: 10px;
  }

  // update form input css
  .ui.form .inline.fields .field:not(.wide) .ui.input,
  .ui.form .inline.field:not(.wide) .ui.input {
    width: 100%;
  }

}