@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import "src/mixins/mixins";

.fcgGridTemplate {
    &__block {
        background-color: $gray--light;
    }

    &__gridInner {
        display: grid;
        gap: 5px;
        box-sizing: border-box;
        background-color: $white;
        width: 160px;
        height: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}