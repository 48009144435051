// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.cpstep1 {
  &__descriptionLabel {
    margin-bottom: 16px;
    @include textStyle($sans-bold, 14px, $black, normal, 24px);
  }

  &__error {
    min-height: 30px;
    display: inline-block;

    img.ui.image {
      display: inline-block;
      margin-right: 10px;
    }
  }

  &__errormsg {
    position: absolute;
    @include errorMsg;
  }

  &__taxaonomyLabel {
    margin-bottom: 16px;
    @include textStyle($sans-bold, 14px, $black, normal, 24px);
  }

  &__descriptionWrapper {
    .sectionsWrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }

  &__descriptionSection {
    width: 55%;
    flex: 1 1 55%;
    position: relative;

    .rich {
      height: auto;
      max-height: 1063px;

      .DraftEditor-root {
        min-height: 325px;
      }
    }
  }

  &__subjectWrapper {
    .sectionsWrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: $gray--light;
      border: 1px solid $gray;
    }
  }

  &__subjectSection,
  &__subjectInfoAllocations {
    max-height: 610px;
    overflow-y: auto;
  }

  &__subjectSection {
    padding: 30px 40px 0 16px;
    border-radius: 4px;
    width: 55%;
    flex: 1 1 55%;
    position: relative;

    .field input {
      padding: 19px 10px !important;
    }

    .field label {
      height: 24px;
      margin-bottom: 1px;
      @include textStyle($sans-reg, 14px, $gray--darkest, bold);

      &:before {
        content: none;
      }
    }
  }

  &__descriptionOverlay {
    cursor: default;

    .confidentialDescLabel {
      @include textStyle($sans-reg, 12px, $gray--darker, normal, 24px);
      position: absolute;
      top: 32px;
      left: 0px;
      right: 0px;
      display: flex;
      z-index: 2;
      justify-content: space-around;
    }

    .overlayWrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 30px;
      background-color: $gray--lightest;
      opacity: 0.8;
      border-radius: 4px;
      z-index: 1;
      text-indent: -999999px;
    }
  }

  &__tAndBSection,
  &__subjectInfoAllocations {
    width: 45%;
    flex: 1 1 45%;
  }

  &__subjectInfoAllocations {
    border-left: 1px solid $gray;

    .tAndBContainer {
      border: 1px solid $gray;
      margin-left: 30px;
      min-height: 400px;
    }

    .subjectInfoContainer {
      min-height: 200px;
    }
  }

  &__customhighlight {
    border: 1px solid $notify-warn-highlight;
  }
}