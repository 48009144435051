// Import
@import "src/global-styles/colors";
@import 'src/global-styles/typography';

.restricted-download{
  .__react_component_tooltip {
    max-width: 265px;
    text-align: left;
    a {
      color: $bcg-green--light-blue-green;
    }
  }
  &__mail{
    width:150px;
  }
  &__hoverlink{
    font-family: $sans-reg;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px;
    color: $bcg-green--light-blue-green;
    &.hoveroverride:hover{
      text-decoration: underline;
      color: $bcg-green--light-blue-green;
    }
  }
  &__iconMarkup {
    width: 15px;
  }
}