@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.peopleInfo_outer {
    background-color: $gray--light;
    font-size: 14px;
    padding: 7px 10px;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    margin-bottom: 9px;
    border-radius: 5px;

    .ui.image {
        display: inline-block;
        vertical-align: top;
        width: 29px;
        border-radius: 17px;
        object-fit: cover;
        height: 29px;
    }

    &__crossicon.ui.image {
        margin-top: 6px;
        cursor: pointer;
        width: 20px;
        height: 20px;
    }

    &__addicon.ui.image {
        cursor: pointer;
        object-fit: scale-down;
    }

    // Custome class section for people info card
    // -------------- Start ---------------//
    &.suggestionListOuterSection {
        padding: 7px 5px;
    }

    &.dragAndDropPeopleSelectorCls {
        display: flex;
        width: max-content;
    }

    &--dragDropHandlerSection {
        width: 18px;
    }

    // -------------- End ---------------//
}

.peopleInfoText {
    &--main {
        display: inline-flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;
        margin: 0 8px;
    }

    &--name {
        font-size: 14px;
        color: $gray--darkest;
        font-family: $sans-reg;
        font-weight: bold;
        height: 15px;

        &_bold {
            font-size: 12px;
        }
    }

    &--title {
        font-size: 10px;
        color: $gray--darker;
        font-family: $sans-reg;
        font-weight: 100;
        height: 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}