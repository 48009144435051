@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.additionallink {
  margin: 8px 0;
  padding: 17px;
  background-color: $white;
  &:hover {
    box-shadow: 1px 0px 5px $gray--dark;
  }
  &__heading {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    letter-spacing: 0;
    display: inline-block;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
}
