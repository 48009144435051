// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";
.cpstep3 {
  &__contacts {
    padding: 20px 35px;
    border-radius: 10px;
    background-color: $gray--light;
  }
}
