@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/mixins/_mixins';

.versionlisting {
  display: flex;
  justify-content: flex-start;

  &__column {
    word-break: break-word;
    letter-spacing: 0;
    line-height: 20px;
    flex: 1 1 140px;
    color: $gray--darkest;
    font-size: 14px;
    font-family: $sans-bold;

    &--fixed {
      max-width: 150px;
      flex: 0 0 140px;
    }

    &--person {
      display: flex;
      flex: 0 0 350px;
    }

    &--title {
      display: flex;
      flex: 1 1 400px;
    }

    &--comments {
      max-width: 255px;
    }

    &--date,
    &--status,
    &--changed_by,
    &--version {
      max-width: 140px;
      flex: 0 0 140px;
    }

    &--change_description {
      display: flex;
      flex: 1 1 400px;
    }
  }

  &__columns {
    display: flex;
    padding: 16px 20px;
    justify-content: space-between;
  }

  &__value {
    word-break: break-word;
    letter-spacing: 0;
    line-height: 20px;
    flex: 1 1 140px;



    &--date,
    &--status,
    &--changed_by,
    &--version {
      max-width: 150px;
      flex: 0 0 140px;
    }

    &--change_description {
      display: flex;
      flex: 1 1 100px;
      max-width: 250px;
    }

    &--updatedFields {
      display: inline-block;
      color: $gray--light;
      max-width: 500px;
    }
  }

  &__inline {
    border-radius: 13px;
    background: $gray--darkest;
    color: $gray--light;
    font-family: $sans-reg;
    font-size: 14px;
    padding: 1px 12px 1px 12px;
    margin: 4px;
  }

  &__center {
    text-align: center;
  }

  &__tablecontent:nth-child(odd) {
    background-color: $gray--light;
    padding: 14px 20px;
  }

  &__tablecontent:nth-child(even) {
    background-color: $white;
    padding: 14px 20px;
  }
}

.table-comtent {
  max-height: 400px;
  overflow: auto;
}