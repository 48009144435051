@import "src/global-styles/colors";
@import "src/global-styles/typography";

.activitesfilters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // &__search {
  //   margin-bottom: 20px;
  //   display: flex;
  //   align-items: center;
  //   .search-bar {
  //     width: 70%;
  //     &__input {
  //       margin-bottom: 0;
  //     }
  //   }
  //   justify-content: space-between;
  // }

  &__status {
    display: inline-block;
    margin-right: 8px;
  }

  &__dropdown {
    display: inline-block;
    margin-left: 8px;
    width: 183px;
  }

  &__status-pill,
  &__status-pill--active {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    font-weight: bold;
    line-height: 16px;
    border-radius: 13.5px;
    background-color: $gray--light;
    padding: 6px 16px;
    cursor: pointer;
  }

  &__status-pill--active {
    color: $white;
    background-color: $black;
    cursor: default;
  }

  &__label {
    display: inline-block;
    margin-left: 15px;
    color: $gray--darker;
    font-family: $sans-bold;
    font-size: 14px;
    letter-spacing: 0.8px;
    line-height: 24px;
    text-transform: uppercase;
  }
}