@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.KPSideBar {
    &__wrap {
        h5 {
            @include textStyle($sans-bold, 14px, $gray--darkest, bold);
            margin-bottom: 7px;
        }
    }

    &__preview {
        bottom: 45px;
        background: #fff;
        margin: 0 1px;

        .unsupportedText {
            display: block;
            text-align: center;
            font-size: 15px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 38px;
            color: $graytextcolor;
            background-color: $gray--darkest;
        }

        &__materialLink,
        &__materialLink:hover {
            display: inline-block;
            width: calc(100% - 45px);
            text-decoration: underline;
            color: $gray--darker;
            word-break: break-word;
            padding-top: 4px;
        }

        .toolH {
            color: #333;
            font-family: HendersonBCGSans-Bold, Arial, sans-serif;
            font-size: 14px;
            font-stretch: normal;
            font-style: normal;
            font-weight: 700;
            letter-spacing: normal;
            line-height: 1.71;
            margin-bottom: 7px;
        }
    }

    &__overlay {
        background: rgba(255, 255, 255, 0.8);
        text-align: center;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        justify-content: center;
        display: flex;
        align-items: center;

        p {
            color: $gray--darkest;
            font-family: $sans-bold;
            font-size: 18px;
            font-weight: bold;
        }
    }

    &__bottom {
        border: 1.5px solid $gray;
        border-top: 0;
        border-radius: 0px 0px 7px 7px;
        position: relative;
        padding: 17px;
        padding-bottom: 15px;
        display: flex;
        bottom: 5px;
    }
}