@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.showMore {
  &__button {
    cursor: pointer;
    width: fit-content;
    margin-left: -3px;
    margin-top: 2%;
  }

  &__showmore-label.ui.label {
    font-size: 1rem;
    color: $bcg-green--blue-green;
    background-color: transparent;
    font-family: $sans-bold;
    font-weight: bold;
    padding: 0px;
  }

  &__icon-square {
    color: $bcg-green--blue-green;
  }

  &__icon-plus.ui.image {
    display: inline-block;
    vertical-align: top;
    height: 15px;
    margin: 2px 3px 0 0px;
  }

  &__icon-minus {
    display: inline-block;
    vertical-align: top;
    width: 12px;
    border-top: 4px solid $bcg-green--blue-green;
    height: 4px;
    margin: 8px 5px 0 0px;
  }

  &__visible {
    overflow-y: visible;
  }

  &__hide {
    overflow: hidden;
  }
}