@import 'src/global-styles/typography';
@import "src/global-styles/colors";

@keyframes color-change {
  0% {
    background-image: linear-gradient(to left, #5D289B, #CD6700);
  }

  100% {
    background-image: linear-gradient(to right, #5D289B, #CD6700);
  }
}

@keyframes left-change {
  0% {
    left: 0;
  }

  100% {
    left: 100%;
  }
}



.banner {
  background: url("../../assets/images/results-background.jpg");
  background-repeat: no-repeat;
  height: 105px;
  background-size: 100%;
  position: fixed;
  z-index: 1004;
}

.page-header {
  width: 100%;

  &__left {
    margin-bottom: 20px;
    margin-left: 30px;
  }

  &__right {
    float: right;
    margin-right: 30px;
    margin-top: 20px;
  }

  &__bottom {
    clear: both;
    margin: 20px 0 0;
  }

  &__ai-message {
    position: absolute;
    top: 13px;
    right: 0px;
    background-color: $whiteBackground;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-radius: 10px 0px 0 10px;
    gap: 8px;


    &--icon {
      width: 18px;
      height: 18px;
    }

    &--text {
      background-image: linear-gradient(to right, #5D289B, #CD6700);
      position: relative;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      animation: color-change 1s infinite;

      &::after {
        content: " ";
        background: #fff;
        opacity: 0.5;
        width: 50px;
        height: 20px;
        position: absolute;
        left: 0;
        animation: left-change 3s infinite;
      }
    }
  }
}