@import 'src/global-styles/colors';
@import "src/mixins/_mixins";
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.caseConsumptionActions {
  width: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  .infopopup {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
  .tooltipwrap__icon {
    display: block;
  }
  .tooltipwrap__popup {
    opacity: 1;
  }
  &__dollar {
    &.ui.image {
      margin: 0 auto;
    }
  }
  &__dots, button {
    background-color: transparent;
    border: none;
    outline: none;
    position: relative;
  }
  &__assetscount {
    top: 0px;
    right: -4px;
    position: absolute;
    height: 18px;
    width: 18px;
    padding-top: 2.5px;
    padding-right: 1px;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0;
    color: $white;
    background-color: $gray--darkest;
    z-index: 1;
  }
  &__popup {
    width: 175px;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 14px;
    li {
      padding: 16px 8px;
    }
  }
  &__briefcasePopup {
    width: 163px;
    padding: 11.48px 8px;
    color: $gray--darkest;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    &__heading {
      font-weight: bold;
      span {
        font-weight: normal;
      }
      margin-bottom: 8.68px;
    }
    &__count {
      font-size: 12px;
      line-height: 20px;
    }
    li &__childcount{
      border-bottom: 1px solid $gray--dark;
      display: inline-block;
      width: 40%;
      margin-bottom: 6px;
      padding-bottom: 6px;
    }
    li:last-child &__childcount{
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  &__billingPopup {
    width: 175px;
    padding: 8px;
    @include textStyle($sans-reg, 14px, $gray--darkest, normal, 24px);
    letter-spacing: 0;
    &__heading {
      font-weight: bold;
      margin-bottom: 8px;
    }
    &__value {
      @include textStyle($sans-reg, 12px, $gray--darkest, normal, 20px);
    }
    li {
      &:not(:last-child) {
        &::after {
          display: block;
          content: '';
          width: 44px;
          border-bottom: 1px solid $checkboxgray;
          margin: 7px 0;
        }
      }
    }
  }
  &__action {
    border-bottom: 1px solid $gray--whitish;
    a {
      @include textStyle($sans-reg, 12px, $gray--blackish, normal, 14px);
      text-decoration: none;
    }
  }
  &__view {
    a {
      display: block;
      color: $bcg-green--light-blue-green;
      text-decoration: underline;
    }
  }
}