@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.info-grid {
  background-color: $white;
  border-radius: 13px;
  padding: 15px 16px 10px 16px;
  margin-bottom: 32px;
  justify-content: space-between;
  display: flex;
  &__left {
    width: calc(100% - 35px);
  }
  &__header {
    &--end {
      justify-content: flex-end;
    }
  }
  &__separator {
    border: 1px solid rgba(151, 151, 151, 0.2);
    display: block;
    margin: 10px 0 10px 0;
  }
  &__toggle {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    cursor: pointer;
    &--bgcolor {
      background-color: $black;
    }
  }
  &__toggle-icon {
    @include alignCenterViaAbsolute;
    &--expand {
      width: 17px;
      height: 17px;
    }
    &--collapse {
      width: 24px;
    }
  }
  &__ul {
    display: flex;
  }
  &__li {
    flex: 1;
    border-width: 1px;
    border-right-color: rgba(151, 151, 151, 0.2);
    border-bottom-color: rgba(151, 151, 151, 0.2);
    &--padding {
      padding: 16px;
    }
    &--no-left-padding {
      padding: 16px 16px 16px 0px;
    }
    &--border-right-bottom {
      border-right-style: solid;
      border-bottom-style: solid;
    }
    &--border-bottom {
      border-bottom-style: solid;
    }
    &--border-right {
      border-right-style: solid;
    }
  }
}
