@import "src/global-styles/colors";
@import "src/global-styles/typography";

.preview {
  position: relative;
  width: 386px;
  flex: none;
  max-width: 50%;

  .unsupportedText {
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 38px;
    color: $graytextcolor;
    background-color: $gray--darkest;
  }

  &__materialLink {
    display: inline-block;
    text-decoration: underline;
    color: $gray--darker;
    padding-top: 4px;
    word-break: break-word;
    width: calc(100% - 45px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__materialLink:hover {
    display: inline-block;
    width: calc(100% - 45px);
    text-decoration: underline;
    color: $gray--darker;
    word-break: break-word;
    padding-top: 4px;
  }

  &__frame {
    width: 100%;
    height: 250px;
    margin-bottom: -4px;
    padding: 0;
    border: 1.5px solid $gray;
    border-bottom: 0;
    border-radius: 7px 7px 0px 0;
  }

  &__document {
    padding: 15px 15px 15px 18px;
    bottom: 45px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    border: 1.5px solid $gray;
    margin: 0 1px;
    border-top: 0;
    border-radius: 0px 0px 7px 7px;

    &-filename {
      align-items: center;
      color: $gray--darkest;
      font-family: $sans-bold;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 26px;
      margin-right: auto;
      flex: none;
      display: inline-block;
      width: calc(100% - 64px);
      word-break: break-word;
    }

    &-download.ui.image {
      right: 10px;
      cursor: pointer;
      flex: none;
      height: 20px;
      margin-left: 16px;
      margin-top: 3px;
    }

    &-icon.ui.image {
      width: 25px;
      margin-right: 20px;
      flex: none;
      height: 27px;
    }
  }

  &__bottomfixed {
    bottom: 6px;
    padding-top: 8px;
    align-items: center;
  }
}