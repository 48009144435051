@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.peopleselector {
    &__info-icon {
        width: 16px;
        height: 16px;
    }

    &__wrapper {
        position: relative;
        border: solid 1px $gray--dark;
        border-radius: 4px;
        background-color: $white;
        padding: 9px 6px 0 6px;

        &--full {
            .react-autosuggest {
                &__container {
                    display: block;
                }

                &__input {
                    width: 100% !important;
                }
            }
        }

        &--max {
            background-color: $gray--lightest;
            border-color: $gray--filler;
        }

        .react-autosuggest {
            &__input {
                padding: 0;
                border: 0 !important;

            }

            &__container {
                ul {
                    >li {
                        pointer-events: none;

                        >div:not(.duplicatePerson) {
                            pointer-events: all;
                        }

                        .duplicatePerson {
                            cursor: auto;
                            background: $gray--lightest;
                            pointer-events: none;

                            span {
                                color: $gray--dark;

                                &.react-autosuggest__title {
                                    position: relative;
                                    left: -33px;

                                    &:after {
                                        background-image: url('../../assets/images/check.svg');
                                        content: "";
                                        position: absolute;
                                        width: 27px;
                                        background-repeat: no-repeat;
                                        top: 0;
                                        bottom: 0px;
                                        right: -35px;
                                        height: 27px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .ui.form & input {
            border: 0;
            padding: 0;
            padding-left: 0px;
            height: 38px;
            margin-bottom: 9px;
            width: 210px;

            &:focus {
                border: 0;
            }
        }
    }

    &__label__disabled {
        opacity: 0.5;
    }

    &__disabled {
        opacity: 0.5;
        background: $gray--light;
        pointer-events: none;
        user-select: none;

        .ui.form .fields & input {
            background-color: $gray--light;
        }
    }

    &__error {
        border-color: $notify-warn-highlight !important;
    }

    &__outer {
        padding: 10px 20px;
        border: 1px solid $gray--dark;
        border-radius: 10px;
        background: $white;
    }

    &__outer-container {
        padding: 10px;
        border: 1px solid $gray--dark;
        border-radius: 10px;
        background: $white;
    }

    &__list-container {
        margin-left: 6px;
        margin-top: 12.5px;
    }

    &__list-heading {
        &>label {
            font-family: $sans-reg;
            font-size: 14px;
            font-weight: bold;
            color: $gray--darkest;
        }
    }

    &__list-inner {
        margin-left: 3px;
        max-height: 217px;
        overflow-y: auto;
    }

    &__infoCard {
        display: inline-block;
        max-height: 166px;
        overflow: auto;

        &::-webkit-scrollbar-track {
            border-radius: 5px;
        }
    }

    &__hide-tooltip {
        .tooltipwrap__popup {
            display: none;
        }
    }
}

.KBStep1 form .field {
    .peopleselector {
        &__wrapper {
            input {
                border: 0 !important;
                padding: 0;
                padding-left: 0px !important;
                height: 38px;
                margin-bottom: 9px;
                width: 210px;

                &:focus {
                    border: 0 !important;
                }
            }
        }
    }
}


.react-autosuggest {
    &__container {
        display: inline-block;
        vertical-align: top;
    }

    &__suggestions-container--open {
        position: absolute;
        left: 0;
        background: $white;
        border: 1px solid $gray--whitish;
        border-radius: 5px;
        z-index: 11;
        max-height: 400px;
        overflow-y: auto;
        width: 100%;
        margin-top: 4px;
    }

    &__suggestion {
        &-wrap {
            display: flex;
            font-size: 14px;
            line-height: 24px;
            color: $gray--darkest;
            font-family: $sans-bold;
            padding: 16px 11px 15px 16px;
            border-bottom: 1px solid $gray--whitish;
            justify-content: space-between;
            cursor: pointer;

            span+span {
                font-size: 12px;
                font-family: $sans-reg;
            }
        }

        &--highlighted {
            >div {
                background-color: $black;
                color: $white;
            }
        }
    }
}

.peopleselector__wrapper--dragDrop {
    overflow: hidden;
}