@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.advancedDropdown {
    background: $white;
    border-radius: 4px;
    box-shadow: 0px 1px 3px 0px rgba(112, 110, 107, 0.5);
    border: 1px solid $gray--darker;
    min-height: 300px;

    &__noresults{
        padding: 30px;
        @include textStyle($sans-bold, 14px, $gray--darkest, normal, 17px);
    }

    &__searchwrapper {
        position: relative;
        background: $white;

        img.ui.image {
            position: absolute;
            left: 30px;
            bottom: 16px;
            cursor: pointer;            
        }
    }
}