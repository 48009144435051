@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.ReactModal {
  &__Content {
    &--othersContacts {
      width: 41vw;
      border-radius: 13px;
    }
  }
}

.otherContacts {
  max-height: 591px;
  border-radius: 13px 13px 0px 0px;
  padding: 16px;

  &__wrap {
    max-height: 518px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    &__heading {
      font-size: 18px;
      line-height: 22px;
      color: $gray--darkest;
      font-family: $sans-bold;
    }
  }

  &__data {
    background-color: $gray--lightest;
    padding: 1px 16px 16px 16px;

    .loader__wrap {
      align-items: flex-start;
    }
  }

  &__modalcross {
    margin-top: -4px;
    cursor: pointer;
    width: 24px;
  }

  &__contactsSection {
    margin-top: 29px;
  }

  &__contactsSection:last-child {
    padding-bottom: 29px;
  }

  &__card {
    margin-top: 20px;
    font-size: 14px;
    line-height: 24px;
    color: $gray--darkest;
    font-family: $sans-bold;

    .customizedCard.ui.card.cardhover {
      margin-top: 0;
      margin-right: 0;

      i.envelope {
        font-size: 16px;
        line-height: 14px;
      }
    }

    &__innerWrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 10px;
    }
  }

  &__nodata {
    opacity: 0.7;
    color: $gray--darker;
  }

  &__cardHeading {
    letter-spacing: 0.8px;
  }

}