@import "src/global-styles/colors";
@import "src/global-styles/typography";

.dropdownCustom {
    .ui.fluid.dropdown {
        box-sizing: border-box;
        width: 100%;
        border: 1px solid $gray--dark;
        border-radius: 7px;
        background-color: $white;
        font-size: 12px;
        line-height: 24px;
        color: $gray--darkest;
        padding: 8px 6px;
    }

    &.dropdownValidationCls {
        .ui.fluid.dropdown {
            border: 1px solid $notify-warn-highlight;
        }

        .ui.selection.active.dropdown .menu {
            border-color: $notify-warn-highlight;
        }
    }

    .ui.dropdown>.text,
    .ui.dropdown .menu>.item {
        color: $gray--darkest;
        font-family: $sans-bold;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 24px;
    }

    .ui.dropdown .menu>.item {
        font-family: $sans-reg;
        margin: 8px;
        border-radius: 4px;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .ui.dropdown.selected,
    .ui.dropdown .menu .selected.item {
        background-color: $bcggreensix;
    }

    .ui.fluid.dropdown>.dropdown.icon {
        padding-top: 13px;
    }
}