@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.consDescripv1{
  &__headingSection{
    margin: 45px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .caseConsumptionActions {
    margin-left: 72px;
    justify-content: flex-end;
  }

  &__mainHeading{
    max-width: 944px;
    h2{
      font-weight: bold;
      font-size: 36px;
      line-height: 46px;
      color: $gray--darkest;
      font-family: $serif-bold;
    }
  }

  &__titledescription{

    &-leftsec{
      width: 261px;
      margin-right: 31px;
      display: inline-block;
      vertical-align: top;
    }
    &-rightsec{
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 292px);
    }
    li:not(:last-child){
      margin-bottom: 16px;
    }
    h5{
      color: $gray--darkest;
      font-size: 12px;
      line-height: 24px;
      font-family: $sans-reg;
      font-weight: bold;
      margin: 0;
    }
    p{
      color: $gray--darkest;
      margin: 0;
      font-size: 16px;
      line-height: 26px;
      font-family: $sans-reg;
    }
  }
}
