@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.caseSearchItem {
	padding: 16px 9px;
	display: flex;
	&__caseNumber {
		margin-right: 16px;
		background-color: $gray--light;
		border-radius: 8px;
		padding: 0 8px;
		flex: 0 0 auto;
		@include textStyle($sans-reg, 14px, $gray--darkest, normal, 24px)
	}
	&__caseTitle {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		@include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px)
	}
}