// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";
.timeAndBillWrapper {
  padding: 16px 11px;
  height: calc(100% - 30px);
  box-sizing: border-box;
  border: 1px solid $gray;
  background-color: $gray--lightest;
  section {
    margin-bottom: 32px;
    label {
      @include textStyle($sans-bold, 14px, $black, normal, 24px);
    }
  }
  &__subtitle {
    @include textStyle($sans-bold, 12px, $gray--darkest, normal, 24px);
  }
  &__value {
    @include textStyle($sans-reg, 12px, $gray--darkest, normal, 20px);
  }
  &__tbdata {
    padding: 16px 17px;
    background: $gray--light;
    border-radius: 10px;
    a {
      @include textStyle($sans-reg, 12px, $gray--darkest, bold, 24px);
    }
    a:hover{
      text-decoration: underline;
    }
  }
  &__disabledLink {
    @include disabledLink();
  }
}