// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";

.uploadFile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: dashed 2px;
  border-color: $gray--dark;
  border-radius: 5px;
  background-color: $white;
  min-height: 60px;

  &.input__error {
    border: 1px solid $notify-warn-highlight;
  }

  input[type="file"] {
    cursor: pointer;
  }

  &__valueandicon {
    color: $gray--darkest;
    max-width: calc(100% - 180px);
    word-break: break-all;
    display: flex;
    align-items: center;

    &--editview {
      max-width: none;
      width: 100%;
    }

    img.ui.image {
      display: inline-block;
      margin: 0 10px 0 0;
      height: 30px;
      vertical-align: bottom;
    }
  }

  &__filename {
    white-space: pre-wrap;
  }

  .ui.form .field &__editfileinput {
    width: 100%;
  }


  .ui.form &__editfileicon.ui.image {
    height: 20px;
    width: 20px;
    margin-left: 10px;
    margin-right: 0;
  }

  &__status {
    img.ui.image {
      float: left;
      height: 25px;
      margin-right: 10px;
    }

    span {
      vertical-align: middle;
      color: $gray--darkest;
    }
  }

  &__placeholder {
    padding-top: 5px;
    color: $gray--dark;
  }

  &__wrap {
    position: relative;
    overflow: hidden;
    margin-left: auto;
    width: 165px;
    flex: none;

    &--rep {
      width: 170px;
    }

    .field.upload .ui.input {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      left: 0;
      padding: 0;
      opacity: 0;
      font-size: 0;
      cursor: pointer;
    }
  }

  &__btn--add,
  &__btn--replace {
    .ui.image {
      display: inline;
      margin-right: 10px;
    }

    text-transform: uppercase;
    border-radius: 5px;
    background-color: $gray--darkest;
    color: $white;
    padding: 8px 10px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1.07px;
    line-height: 20px;
    text-align: right;
    white-space: nowrap;
    cursor: pointer;
  }
}