@import "src/global-styles/colors";
@import "src/global-styles/typography";

.loader {
    &__wrap{
        img.ui.image{
            height: 25px; 
        }
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    &__text{
        font-size: 14px;
        line-height: 24px;
        font-family: $sans-bold;
        color: $gray--darkest;
        padding-top: 12px;
        letter-spacing:0.8px;
    }
}