@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.caseContacts {
  &__viewOtherContacts {
    padding: 16px 0px;
    text-align: right;
    &__link {
      color: $bcg-green--light-blue-green;
      font-family: $sans-reg;
      font-size: 12px;
      line-height: 24px;
      &:hover {
        text-decoration: underline;
        color: $bcg-green--light-blue-green;
      }
    }
  }
  .disabled {
    pointer-events: none;
    cursor: default;
  }
}
