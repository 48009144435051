// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.kpstep2{
    &__wrap{
        .fields .field .peopleselector__label {
            display: inline-block;
        }
    }
    // &__disabled-field{
    //     opacity: 0.5;
    //     pointer-events: none;
    // }
}

.ui.radio.checkbox{
    input:checked, input:focus:checked{
        ~ label:after{
            background-color: #3FAD93;
        }
    } 
}