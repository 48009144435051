@import "src/global-styles/colors";
@import "src/global-styles/typography";

.know-no-results {
  border-radius: 4px;
  background-color: $white;
  padding: 90px 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &__header {
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 24px;
    line-height: 40px;
  }

  &__suggestions {
    color: $gray--darkest;
    font-family: $sans-reg;
    font-size: 14px;
    line-height: 24px;
  }

  &__not-equal {
    height: 64px;
    width: 64px;
    border-radius: 50%;
    color: $bcg-blue;
    border: solid 2px $bcg-blue;
    font-size: 24px;
    line-height: 60px;
    text-align: center;
    margin-right: 32px;
    justify-content: center;
    display: flex;

    img.ui.image {
      width: 22px;
    }
  }
}