// import color & typography
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";
.cpstep2 {
  &__relatedCases {
    margin-top: 32px;
    min-height: 350px;
    &__label {
      @include textStyle($sans-bold, 14px, $gray--darkest, bold, 24px);
      margin-bottom: 14px;
    }
  }
}
