@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.kpgridlist {
    &__buttonSec {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: 0 0 15px 0;

        &>button:first-child {
            margin: 0 10px 0 0;
        }
    }
}