// Import
@import "src/global-styles/colors";
@import "src/mixins/mixins";

// Pixels
$pixels: (
  "two": 2px,
  "fifteen": 15px,
  "oneEighty": 180px,
);
// nested scss classes
.picker {
  border-radius: 7px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5);  
  &__row {
    padding: 0 map-get($pixels, "fifteen");
  }
  &__col {
    padding-top: map-get($pixels, "fifteen");
    padding-bottom: map-get($pixels, "fifteen");
  }
  &__image {
    // height: map-get($pixels, "oneEighty");
    &:hover {
      cursor: pointer;
      @include borderBox;
    }
    &--active {
      @include borderBox;
    }
  }
}
