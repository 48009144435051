@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';
@import "src/mixins/mixins";

@mixin imageDisplay(){ 
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.info-card {
  display: flex;
  &__image-wrap {
    @include imageDisplay;
    flex: 0 0 35px;
    margin-top: 5px;
    &--bg-green {
      background-color: $bcggreenseven;
    }
  }
  &__image {
    @include alignCenterViaAbsolute;
    &--bound-square {
      width: 17px;
      height: 17px;
    }
    &--bound-rect {
      width: 20px;
      height: 12px;
    }
    &--bound-with-radius {
      @include imageDisplay;
    }
  }
  &__text {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
  }
  &__label {
    @include textStyle($sans-bold, 12px, $gray--darkest, normal, 24px);

  }
  &__value {
    @include textStyle($sans-reg, 16px, $gray--darkest, normal, 26px);
  }
}