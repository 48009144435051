@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import 'src/mixins/_mixins';

.kpShowMore {
    cursor: pointer;
    display: inline-block;

    &__icon-plus.ui.image {
        display: inline-block;
        vertical-align: top;
        height: 15px;
        margin: 2px 3px 0 0px;
    }

    &__icon-minus {
        display: inline-block;
        vertical-align: top;
        width: 12px;
        border-top: 4px solid $bcg-green--blue-green;
        height: 4px;
        margin: 8px 5px 0 0px;
    }

    &__kpShowMore-label.ui.label {
        font-size: 1rem;
        color: $bcg-green--blue-green;
        background-color: transparent;
        font-family: $sans-bold;
        font-weight: bold;
        padding: 0px;
    }
}