@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.assistCrossWrapper {
  position: relative;
  &__assistBtn.ui.button {
    position: absolute;
    padding: 5px 8px;
    text-align: left;
    top: -40px;
    right: 0px;
    height: 24px;
    border-radius: 5px;
    background-color: $gray--darkest;
    @include textStyle($sans-reg, 10px, $white, bold, 14px);
    letter-spacing: 0.8px;
    i {
      color: $white;
      margin: 0 0 0 16px;
    }
  }
  &__cross {
    color: $white;
    width: 24px;
    height: 24px;
    padding: 4px;
    border-radius: 50%;
    &.ui.image {
      background-color: $gray--darkest;
      position: absolute;
      top: -40px;
      right: 0px;
    }
  }
}