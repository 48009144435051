@import 'src/global-styles/colors';
@import 'src/global-styles/typography';

.kbeWarning {
    padding: 22px 15px 30px;
    width: 490px;

    &__title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        justify-content: center;

        .copyicon {
            margin-top: 2px;
        }

        &--left {
            justify-content: left;
        }

        .check-icon {
            width: 24px;
            height: 24px;
            font-size: 24px;
            vertical-align: middle;
            margin-top: 1px;
            color: $bcggreenthree
        }
    }

    &__heading {
        font-size: 14px;
        line-height: 20px;
        color: $gray--darkest;
        font-family: $sans-bold;
        margin: 0 0 0 11px;
        align-self: center;
    }

    &__ctas {
        text-align: center;

        &--corner {
            justify-content: space-between;
            display: flex;
        }
    }

    &__cta {
        &.ui.button {
            padding: 10px 30px;
        }
    }

    &__cta.ui.button+.button {
        margin-left: 20px;
    }

    &__cta.ui.button:only-child {
        margin-left: 0;
    }

    &__comment {
        margin-bottom: 15px;

        .ui.form textarea {
            border: 0;
        }

        &-textarea {
            resize: none !important;
            height: 117px;
        }

        &-wrapper {
            position: relative;
            border: 1px solid $bcggrayone;

            span.counter {
                text-align: right;
                display: block;
                padding-right: 15px;
                padding-bottom: 5px;
            }
        }
    }

    &__cmp {
        padding-top: 10px;

        .kbeWarning__title {
            margin: 15px 0;
        }
    }

    &__toggleswitch {
        padding: 14px 10px;
        background: $gray--darkest;
        border-radius: 7px;
        display: flex;
        align-items: center;

        >span {
            padding-left: 16px;
            color: $white;
        }
    }

    &__cancel_cls {
        float: left;
    }

    &__customCls {
        padding: 20px;

        &>.kbeWarning__title {
            align-items: center;
        }
    }
}