@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.faceCardHeader {
    min-height: 73px;
    background: $fcg-green;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__left {
        display: flex;
        align-items: center;
        margin-left: 24px;

        &__logoIcon {
            margin-right: 14px;
        }

        &__logoText {
            color: $white;
            font-size: 24px;
            font-family: $sans-bold;

            sup {
                margin-left: 8px;
                font-size: 14px;
            }
        }
    }

    &__right {
        display: flex;
    }
}