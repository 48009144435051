@import "src/global-styles/colors";
@import "src/global-styles/typography";

.fullpageloader {
    &__wrap{
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 1111;
        background:rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        &--insection{
            position: absolute;
            z-index: 1;
        }
    }
    &__text{
        font-size: 14px;
        line-height: 24px;
        font-family: $sans-bold;
        font-weight: bold;
        color: $white;
        padding-top: 12px;
        letter-spacing: 1px;
    }
}