@import 'src/global-styles/colors';

.slctrbase {
    position: relative;
    margin-top: 15px;

    &__img {
        position: absolute;
        right: 0px;
        top: 0;
        height: 44px;
        width: 44px;
        margin: 6px 5px;
        cursor: pointer;
    }

    &.datapresent {
        .slctrbase__img {
            right: 10px;
        }
    }

    &__error {
        .field.field input {
            border-color: $notify-warn-highlight  !important;
        }
    }

    &.multiselect {
        &.disabled {

            >.field,
            .slctrbase__wrapinput {
                pointer-events: none;
                opacity: 0.5;

                input {
                    background: $gray--light;
                }
            }
        }
    }

    &__disabled {
        opacity: 0.5;
    }

    &__wrapinput {
        border: solid 1px $gray--dark;
        border-radius: 4px;
        background-color: $white;
        padding: 9px 55px 0 6px;
        overflow-y: auto;
        max-height: 150px;

        li {
            display: inline-block;
            padding: 8px;
            background-color: $gray--light;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            color: $gray--darkest;

            img.ui.image {
                display: inline-block;
                margin-left: 7px;
                cursor: pointer;
            }
        }
    }
}