@import "src/global-styles/colors";
@import "src/global-styles/typography";

.ui.popup{
    max-width: 328px;
    padding: 16px;
    z-index: 10;
    h5, p{
        font-size: 14px;
        line-height: 20px;
        color: $gray--darkest;
        font-family: $sans-reg;
    }
    h5{
        font-family: $sans-bold;
        margin-bottom: 8px;
    }
}
