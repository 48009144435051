@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';

.taxonomylisting {
  position: relative;
  width: 100%;

  &__sectorslist {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__polyImg {
    margin: -2px 6px 0px 0px;
  }

  &__searchtags {
    display: flex;
    flex-direction: row;
    width: calc(100% - 75px);
  }

  &__sectorCategory {
    &:first-child {
      margin-top: 0;
    }

    margin-top: 16px;
    margin-bottom: 4px;
    display: inline-block;
    margin-left: 10px;
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.8px;
    line-height: 24px;
  }

  .showMore {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &__button {
      margin-top: 2px;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    flex-flow: wrap;
  }

  &__sector {
    margin-bottom: 8px;
    margin-right: 15px;

    &.tagvisible {
      display: block;
    }

    &.taghidden {
      display: none;
    }

    &:first-child>.taxonomylisting__sectorLabel,
    &.taghidden+&>.taxonomylisting__sectorLabel {
      margin-left: 0;
    }
  }

  &__sectorsection {
    padding-bottom: 20px;
  }

  &__label {
    position: relative;
    display: inline-block;
    margin-left: 15px;
    vertical-align: top;
    padding: 3px 0 4px;

    &::after {
      content: "\f0d7";
      font-family: 'Icons', sans-serif;
      transform: rotate(-90deg);
      display: inline-block;
      color: $gray--dark;
      margin-top: 4px;
      vertical-align: top;
      margin-left: 0;
      position: absolute;
      top: 0;
      right: -19px;
    }
  }

  &__sectorLabel {
    padding: 3px 16px 5px;
    background-color: $gray--lighttwo;
    margin-right: 8px;
    border-radius: 16px;
    display: inline-block;
    vertical-align: top;
    color: $gray--darkest;
    margin-left: 14px;

    &-star::after {
      font-family: 'Icons', sans-serif;
      color: #3fad93;
      content: "\f005";
      width: 9px;
      height: 9px;
      margin-left: 8px;
      font-size: 8px;
      font-weight: normal;
      vertical-align: bottom;
    }
  }

  &__sectorCustodian {
    background-color: $white;
    padding: 0 12px;
    margin-bottom: 4px;
    margin-top: 4px;
    border: 1px solid $gray--dark;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    padding-right: 190px;
    margin-left: -17px;
    padding-top: 8px;
  }

  &__custodianLabel {
    position: absolute;
    right: 12px;
    top: 8px;
  }

  &__collapseSection {
    position: absolute;
    z-index: 9;
    right: 0;
    top: -5px;
    cursor: pointer;
  }

  &__collapseImage {
    &.ui.image {
      display: inline;
      margin-left: 10px;
    }
  }

  &__collapse {
    color: $gray--darkest;
    font-family: $sans-bold;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 14px;
    text-align: right;
  }

}