@import "src/global-styles/colors";
@import "src/global-styles/typography";

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    border-radius: 4px;
    margin: 16px 0;

    &__prev,
    &__next {
        display: flex;

        &.disabled {
            display: none;
        }
    }

    &__prev__link,
    &__next__link {
        width: 35px;
        border-radius: 5px;
        color: $bcg-green--light-blue-green;
        background-color: $bcg-green--light-blue-green;
        margin: 0 6px;
        font-size: 12px;
        line-height: 35px;
        letter-spacing: 1px;
        position: relative;
        cursor: pointer;
        text-align: center;
        overflow: hidden;

        &:before {
            color: $white;
            position: absolute;
            top: 11px;
            left: 4px;
            content: "";
            width: 20px;
            height: 10px;
            transform: rotate(45deg);
            background: url(../../assets/images/arrow_left_down_right_up.svg) no-repeat;
        }

        &:hover {
            color: $bcg-green--light-blue-green;
        }
    }

    &__prev__link {
        &:before {
            transform: rotate(225deg);
            left: 10px;
            top: 14px;
        }
    }

    &__item {
        list-style-type: none;
        display: flex;

        &.active .pagination__link {
            color: $gray--darkest;
            font-family: $sans-bold;
        }
    }

    &__link {
        display: block;
        padding: 8px 12px;
        line-height: 1.25;
        background-color: $white;
        font-size: 14px;
        font-family: $sans-reg;
        color: $gray--darker;
    }

    &__break-me {
        a {
            display: block;
            padding: 7px 9px;
            line-height: 1.25;
            background-color: $white;
            color: $gray--darker;
        }
    }
}