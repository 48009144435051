@import "src/global-styles/colors";
@import "src/global-styles/typography";

.addedki {
  width: 100%;
  font-family: $sans-reg;
  letter-spacing: normal;
  display: flex;
  justify-content: space-between;

  &__content {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  &__ctasdisabled {
    opacity: 0.5;
  }
  &__ctas {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button,
    a {
      margin-left: 15px;
      background: none;
      border: 0;
      &:focus {
        outline: 0
      }
    }
    a {
      display: inline-block;
    }
    
  }

  &__date {
    padding-right: 43px;
    width: 155px;
    text-align: left;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
    font-weight: 400;
    font-family: $sans-reg;
  }
}
