@import 'src/global-styles/colors';
@import 'src/global-styles/typography';
@import 'src/global-styles/responsive';
@import 'src/mixins/_mixins';

.caseDurationAndCapture {
  display: flex;
  margin-bottom: 36px;
  justify-content: space-between;
  &__leftSection {
    display: inline-block;
    .caseType, .timeDuration {
      display: inline;
    }
    .caseType{
      padding: 5px 28px 5px 10px;
    }
  }
  &__rightSection {
    @include textStyle($sans-reg, 14px, $gray--darkest, normal, 16px);
    label {
      font-family: $sans-bold;
      padding-right: 2px;
    }
  }
}