@import "src/global-styles/responsive";
@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.casedata {
	padding: 20px 10px;
	display: inline-block;
	width: 100%;
	&__clientdetails {
		display: inline-block;
		border-bottom: 0.5px solid $checkboxgray;
		margin-bottom: 10px;
		padding: 5px 8px;
		width: 100%;
		@include textStyle($sans-reg, 14px, $gray--darkest, normal, 24px);
		&:last-child {
			border-bottom: none;
		}
	}
	&__des {
		width: 80%;
		display: inline-block;
		padding-bottom: 5px;
	}
	&__title {
		display: block;
	}
	&__copy {
		width: 10%;
		display: inline-block;
	}
}
