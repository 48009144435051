@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.search-bar {
  &__input {
    color: $gray--darker;
    font-family: $sans-reg;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 15px;

    .ui.input {
      width: 100%;
      input {
        padding: 15px;
      }
    }
  }
}