@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/global-styles/responsive";

.attachment {
  display: flex;
  width: 100%;
  align-items: center;

  .attachment__image {
    width: 10%;
    height: 32.5px;
    object-fit: contain;
    max-width: 67px;
    min-height: 32.5px;
    margin: 3px 0 0;
  }

  .attachment__content {
    width: calc(100% - 80px);
    padding-left: 8px;
    text-align: left;
    overflow-wrap: break-word;
    word-break: break-all;

    .attachment__title {
      font-family: $sans-bold;
      font-size: 14px;
      line-height: 24px;
      color: $gray--darkest;
      margin: 0;
      overflow: hidden;
    }

    .attachment__sub-title {
      font-family: $sans-reg;
      line-height: 20px;
      font-size: 12px;
      color: $gray--darker;
    }
  }

  .attachment__icon {
    width: 100%;
    height: 100%;
  }

  .labIcon {
    height: 16px;
    width: 48px;
  }
}
