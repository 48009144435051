@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/mixins";

.slick-slider {
  .slick-prev,
  .slick-next {
    background-color: $bcg-green--light-blue-green;
    border: 0;
    font-family: $sans-reg;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    text-align: center;
    color: $white;
    border-radius: 5px;
    letter-spacing: 0.8px;
    width: auto;
    height: auto;
    right: 10px;
    position: absolute;
    bottom: -88px;
    top: auto;
    left: auto;
    padding: 8px 20px;
    text-transform: uppercase;
    z-index: 1;
    &.slick-disabled{
        display: none;
    }
  }
  .arrow.right {
      margin: 0 0 0 5px;
    i.icon.arrow.right:before {
      vertical-align: middle;
    }
  }

  .slick-prev:before,
  .slick-next:before {
    content: none;
  }
}
