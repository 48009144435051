@import "src/global-styles/colors";
@import "src/global-styles/typography";

.popupModal {
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 7px;
    background-color: $white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);

    &__header {
        padding: 13px 24px;
        background-color: $gray--darkest;
        border-radius: 7px 7px 0 0;
        color: $white;
        letter-spacing: 0;
        line-height: 24px;
    }

    &__headerClose.ui.button {
        height: 22px;
        width: 22px;
        border-radius: 50%;
        border: none;
        float: right;
        background: $gray--brownish;
        margin: 12px 16px;
        padding: 0px 6px;
        image {
            width: 11px;
            height: 11px;
        }
    }

    &__title {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        justify-content: center;
        .check-icon {
            width: 24px;
            height: 24px;
            font-size: 24px;
            vertical-align: middle;
            margin-top: 1px;
            color: $bcggreenthree;
        }
    }

    &__heading {
        font-size: 14px;
        line-height: 20px;
        color: $gray--darkest;
        font-family: $sans-bold;
        margin: 0 0 0 11px;
        align-self: center;
    }

    &__buttons {
        text-align: center;
        &--button.ui.button {
            margin: 20px 30px 30px 30px;
            width: 130px;
        }
        &--button.ui.button:first-child {
            margin-right: 30px;
        }
        &--button.ui.button:only-child {
            margin-right: 0;
        }
    }
    &__comment {
        margin-bottom: 15px;
        .ui.form textarea {
            border: 0;
        }
        &-textarea {
            resize: none !important;
            height: 117px;
        }
        &-wrapper {
            position: relative;
            border: 1px solid $bcggrayone;
            span.counter {
                text-align: right;
                display: block;
                padding-right: 15px;
                padding-bottom: 5px;
            }
        }
    }
}
