@import "src/global-styles/colors";
@import "src/global-styles/typography";

.txbase {
    &__img {
        position: absolute;
        right: 0;
        top: 0;
        height: 44px;
        width: 44px;
        background: $gray--darkest;
        border-radius: 5px;
        margin: 6px 5px;
        padding: 13px;
        cursor: pointer;
    }

    &__error {
        .field.field input {
            border-color: $notify-warn-highlight !important;
        }

        .txbase__wrapinput {
            border-color: $notify-warn-highlight !important;
        }

        .txbase__suggestions {
            border-top-color: $notify-warn-highlight !important;
        }
    }

    &__disabled {
        opacity: 0.5;
    }

    &__existing {
        pointer-events: none;
        cursor: text;
    }

    &__existingicon {
        padding: 9px 0px;
    }

    &__wrapinput {
        position: relative;
        border: solid 1px $gray--dark;
        border-radius: 4px;
        background-color: $white;
        padding: 9px 55px 0 6px;

        li {
            //display: inline-block;
            padding: 8px;
            background-color: $gray--light;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            color: $gray--darkest;
            display: inline-flex;

            img.ui.image {
                display: inline-block;
                margin-left: 7px;
                cursor: pointer;
            }
        }

        li.txbase__autoTagged {
            background-color: #E0F1FF;
        }

        &>ul>li>.tooltipwrap {
            margin-left: 0;

            &>.tooltipwrap__icon>.txbase__poly-img {
                margin: 0 10px 0 7px;
                cursor: default;

                &.txbase__iIcon-img {
                    margin: -2px 6px 0 7px;
                }
            }
        }
    }

    &__autotaggedmsg {
        font-size: 10px;
        color: #707070;
        line-height: 24px;
        margin-bottom: 0;

        &::before {
            height: 12px;
            width: 12px;
            content: "";
            border-radius: 10px;
            border: 1px solid #82B4E1;
            background-color: #E0F1FF;
            margin-right: 3px;
            vertical-align: text-top;
            display: inline-block;
        }
    }

    &__custodianWrapper {
        margin-top: 30px;
    }

    .ui.form &__havesuggestions {
        .field.field {
            margin: 0;

            input {
                border-bottom: 0px;
                border-radius: 4px 4px 0 0;
            }
        }

        .txbase__wrapinput {
            border-bottom: 0;
            border-radius: 4px 4px 0 0;
        }
    }

    &__suggestions {
        border: solid 1px $gray--dark;
        border-radius: 0 0 4px 4px;
        background-color: $white;
        padding: 15px 8px 10px 8px;
        position: relative;

        label {
            padding-right: 87px;
            margin-bottom: 14px;
            display: inline-block;
            height: auto !important;
        }

        .thumbsupdown__wrap {
            position: absolute;
            width: 79px;
            right: 7px;
            top: 13px;
        }

        ul {
            max-height: 138px;
            overflow-y: auto;

            &>.txbase__suggestions__tooltip {
                vertical-align: top;

                &>.tooltipwrap__icon>.txbase__suggestions__tooltip__disabled {
                    background-color: $gray--light--disable;
                    color: $gray--dark;
                }
            }
        }

        li {
            display: inline-block;
            padding: 4px 8px;
            background-color: $gray--light;
            margin-right: 8px;
            margin-bottom: 8px;
            border-radius: 4px;
            color: $gray--darkest;

            &[index=fa55a41b-5ed8-4966-a630-b482683b2b00] {
                display: none;
            }

            &.hideSuggestionTags {
                display: none;
            }

            img.ui.image {
                display: inline-block;
                margin-left: 7px;
                cursor: pointer;
            }
        }

        &>ul>li>.tooltipwrap {
            margin-left: 0;

            &>.tooltipwrap__icon>.txbase__poly-img {
                margin: 0 10px 3px 7px;
                cursor: default;
            }
        }

        &__tooltip {
            margin-left: 0;

            &__disabled {
                cursor: default;

                img {
                    opacity: 0.5;
                }

                &>.tooltipwrap>.tooltipwrap__icon>.txbase__poly-img {
                    margin: 0 10px 3px 7px;
                    cursor: default;
                }
            }

        }
    }
}

.v2 {
    display: flex;

    &__title {
        display: inline-block;
        width: 102px;
        margin-right: 14px;
        font-size: 12px;
        line-height: 16px;
        color: $gray--darkest;
    }

    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
        background: $gray--darkest;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        cursor: pointer;

        img {
            width: 16px;
        }
    }

    &__count {
        display: flex;
        align-items: center;
        margin-right: 8px;
        font-family: $sans-bold;
        font-size: 14px;
        line-height: 16px;
        color: $gray--darkest;
    }
}