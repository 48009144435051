@import "src/global-styles/colors";
.help-icon {
  -webkit-transition: background 0.2s, color 0.2s;
  -moz-transition: background 0.2s, color 0.2s;
  transition: background 0.2s, color 0.2s;
  display: inline-block !important;
  cursor: pointer;
  width: 56px !important;
  height: 56px !important;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  &:hover {
    color: #000;
    box-shadow: 0 0 0 1px $bcg-light-yellow;
    background-color: rgba(212, 223, 51, 0.2);
  }
  &:hover:after {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  &:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: "";
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
    top: -16px;
    left: -16px;
    padding: 16px;
    box-shadow: 0 0 0 1px $bcg-light-yellow;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(0.8);
    opacity: 0;
    background-color: rgba(238, 232, 154, 0.15);
  }
  &:before {
    line-height: 56px;
  }
}
