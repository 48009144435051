@import "src/global-styles/colors";
@import "src/global-styles/typography";
@import "src/mixins/_mixins";

.entityitem {
  background-color: $white;
  font-family: $sans-reg;
  padding: 16px;
  letter-spacing: normal;
  border-bottom: 1px solid $gray--dark;
  margin-bottom: 2px;
  max-width: 100%;
  min-height: 90px;

  &:hover {
    box-shadow: 1px 0px 5px $gray--dark;
    cursor: pointer;
  }

  &__content {
    display: flex;
    min-height: 58px;
    width: 100%;
    justify-content: space-between;
    max-width: 100%;
  }
  &__dateandtype {
    display: flex;
    flex-direction: column;
    flex: 0 0 125px;
    max-width: 12%;
    justify-content: flex-start;
    margin-right: 10px;
    &__date {
      height: 35px;
      color: $gray--darkest;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 20px;
    }
    &__type {
      min-height: 30px;
      color: $gray--darker;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 0.8px;
      line-height: 16px;
      text-transform: uppercase;
    }
  }
  &__details {
    flex: 0 0 82%;
    max-width: 82%;
    margin-right: 100px;

    &__iconstitle {
      display: flex;
    }

    &__pa {
      display: block;
      margin-bottom: 5px;
      .pa-pill {
        @include textPill;
        padding: 1px 10px;
        display: inline-block;
        font-size: 12px;
        margin-right: 10px;
        margin-bottom: 5px;
        &.tagged::before {
          background: url("../../assets/images/star.svg") no-repeat !important;
          content: "";
          width: 15px;
          height: 15px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
        .ui.image {
          display: inline;
        }
      }
    }
    &__content {
      display: flex;
    }
    &__icon {
      height: 22px;
      width: 16px;
      margin-right: 8px;
      align-self: flex-end;
      &.ui.image {
        display: inline;
      }
    }
    &__desc {
      word-break: break-word;
      @include textStyle($sans-reg, 14px, $gray--darkest, normal, 21px);
    }
    &__filename {
      @include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px);
      @include multilineellipsis(1);
      display: flex;
      align-items: center;
    }
    &__name {
      @include textStyle($sans-reg, 14px, $gray--darkest, normal, 21px);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        color: $anchor-color;
      }
    }
    &__subtitle {
      @include textStyle($sans-reg, 14px, $gray--darkest, bold, 24px);
      @include multilineellipsis(1);
    }
  }

  &__pa {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex: 0 0 134px;
    &__star {
      color: $bcg-green;
      &.icon {
        font-size: 12px;
      }
    }
    &__recommended {
      flex: 0 0 113px;
      color: $gray--darkest;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}
